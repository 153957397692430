<template>
  <div :class="`${state !== 'idle' ? 'ui raised segment' : ''} list-selector-container`">
    <div v-if="state === 'idle'" class="ui action input">
      <input type="text" :placeholder="`Seleccione un/una ${elementSelect}`" readonly v-model="selectedShow" @focus="selectMethod">
      <div class="ui button" @click="selectMethod">Seleccionar</div>
    </div>
    <div v-if="state === 'selecting'">
      <div class="ui center aligned basic segment">
        <div class="ui icon input">
          <input id="inputSelector" v-focus-on-create type="text" placeholder="Buscar..." v-model="searchText" @keyup="keyEvent">
          <i class="inverted red circular times link icon" @click="keyEvent({ key: 'Escape' })"></i>
        </div>
        <div class="ui horizontal divider" v-if="showNewButton">O</div>
        <div class="ui teal labeled icon button" v-if="showNewButton" @click="handleNewButton">
          {{ `Crear nuevo/nueva ${elementSelect}` }}
          <i class="add icon"></i>
        </div>
      </div>
      <div class="ui link cards centered cards-container">
        <div class="card card-hover" v-for="item in data" :key="item.key" @click="selectItem(item)">
          <div class="content">
            <div class="header" style="font-size: 13px;">{{ item[elementCaption] }}</div>
            <div class="description" style="font-size: 12px;"> {{ item[elementSubCaption] }} </div>
          </div>
          <!-- <div class="ui bottom attached button" @click="selectItem(item)">
            <i class="add icon"></i>
            Seleccionar
          </div> -->
        </div>
      </div>
    </div>
    <slot name="editor" v-if="state === 'new'"></slot>
  </div>
</template>

<script>
export default {
  name: 'list-selector',
  computed: {
    countElements () {
      return this.elements.lenght
    }
  },
  props: {
    elements: {
      type: Array,
      default: () => {
        return []
      }
    },
    elementSelect: {
      type: String,
      default: '##nothing##'
    },
    elementCaption: {
      type: String,
      default: 'nombre'
    },
    elementSubCaption: {
      type: String,
      default: 'identificacion'
    },
    elementSelectEvent: {
      type: Function,
      default: null
    },
    showNewButton: {
      type: Boolean,
      default: true
    },
    clickNewButton: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      state: 'idle',
      selected: null,
      selectedShow: '',
      data: [],
      searchText: ''
    }
  },
  mounted () {
    this.data = this.elements
  },
  methods: {
    selectMethod () {
      this.state = 'selecting'
    },
    selectItem (item) {
      this.selected = item
      this.state = 'idle'
      this.searchText = ''
      if (this.elementSelectEvent !== null) this.elementSelectEvent(item)
    },
    keyEvent (e) {
      if (e.key === 'Escape') this.state = 'idle'
    },
    setElement (item) {
      this.selectItem(item)
    },
    handleNewButton () {
      this.state = 'new'
    },
    handleCloseSlot (item = null) {
      if (item !== null) this.selectItem(item)
      else if (item === null) this.state = 'selecting'
    }
  },
  watch: {
    elements: function (val) {
      this.data = val
    },
    countElements: function (val) {
      this.data = this.elements
    },
    searchText: function (val) {
      if (val !== '') {
        this.data = this.elements.filter(element => element[this.elementCaption].toString().toUpperCase().indexOf(val.toUpperCase()) > -1 || element[this.elementSubCaption].toString().toUpperCase().indexOf(val.toUpperCase()) > -1)
      } else if (val === '') {
        this.data = this.elements
      }
    },
    selected: function (val) {
      if (val !== null) this.selectedShow = val[this.elementCaption]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/soporte-variables.scss';

  .list-selector-container {
    padding: 0px 10px 10px 10px;
    .cards-container {
      max-height: 280px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .card-hover {
    &:hover {
      background: #f1f2f6!important;
    }
  }
</style>
