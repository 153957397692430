<template>
  <div class="item">
    <!--  -->
    <div class="content">
      <div class="ui divider"></div>
      <div class="header" >
        <div :class="`ui ${elemento.traza.auto === 5 ? 'red' : elemento.traza.auto === 4 ? 'green' : elemento.traza.auto === 3 ? 'orange' : elemento.traza.auto === 2 ? 'yellow' : 'teal'} basic tag label`">
          <i :class="`${elemento.traza.auto === 5 ? 'ban' : elemento.traza.auto === 4 ? 'check circle' : elemento.traza.auto === 3 ? 'clock circle' : elemento.traza.auto === 2 ? 'info circle' : 'registered'} icon`"></i>
          {{ elemento.traza.nombre }}
        </div>
        <span class="span-aux">{{ elemento.traza.auto === 2 ? 'por' : 'realizado por' }}</span>
        <div class="ui image label">
          <img :src="`data:${'image/png'};base64,${elemento.usuario.imagen}`">
          {{ elemento.usuario.nombres + ' ' + elemento.usuario.apellidos }}
          <div class="detail">{{ elemento.usuario.iniciales }}</div>
        </div>
        <span class="span-aux" v-if="elemento.traza.auto === 2">a</span>
        <div class="ui image label" v-if="elemento.traza.auto === 2">
          <img :src="`data:${'image/png'};base64,${elemento.asignado.imagen}`">
          {{ elemento.asignado.nombres + ' ' + elemento.asignado.apellidos }}
          <div class="detail">{{ elemento.asignado.iniciales }}</div>
        </div>
        <span class="span-aux">el dia</span>
        <div class="ui label">
          <i class="calendar icon"></i>
          {{ new Date(elemento.fecha).toLocaleDateString() }}
        </div>
        <span class="span-aux">a las</span>
        <div class="ui label">
          <i class="clock icon"></i>
          {{ new Date(elemento.fecha).toLocaleTimeString() }}
        </div>
      </div>
      <div v-if="(elemento.respuesta !== ' ' && elemento.respuesta !== '' && elemento.respuesta !== '<P></P>') || anexos.length > 0" class="description">
        <div class="ui message">
          <div class="ui dividing header">Descripcion</div>
          <editor-content class="editor__content" :editor="editor" style="opacity: 0.7"/>
        </div>
        <list-anexos v-if="anexos.length > 0" :items="anexos" :editable="false"/>
      </div>
    </div>
  </div>
</template>

<script>
import ListAnexos from './ListAnexos'
import { Editor, EditorContent } from 'tiptap'
import { Blockquote, HardBreak, Heading, OrderedList, BulletList, ListItem, TodoItem, TodoList, Bold, Code, Italic, Link, Strike, Underline, History } from 'tiptap-extensions'
export default {
  name: 'item-traza',
  props: {
    elemento: {
      type: Object
    }
  },
  components: {
    EditorContent,
    ListAnexos
  },
  mounted () {
    this.state = 'load'
    this.$soportecore.api.source.getAnexos(3, this.elemento.auto).then((rta) => {
      this.anexos = rta.result
      this.state = 'idle'
    }).catch((err) => {
      this.state = 'idle'
      if (err.code !== 400) $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar obtener los anexos` })
      console.log(err)
    })
  },
  data () {
    return {
      editor: new Editor({
        editable: false,
        extensions: [
          new Blockquote(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new TodoItem(),
          new TodoList(),
          new Bold(),
          new Code(),
          new Italic(),
          new Link(),
          new Strike(),
          new Underline(),
          new History()
        ],
        content: this.elemento.respuesta
      }),
      state: 'idle',
      anexos: []
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/main.scss';
  .span-aux {
    font-weight: 800;
    font-size: 14px;
    margin: 10px 5px;
  }
</style>
