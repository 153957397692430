// listado de peticiones
const uris = require('./paths.js').default
// Motor de Requerimientos
const engine = require('./engine')
// Controlador de promesas
const q = require('q')
// Encriptador de contraseñas
const crypto = require('./crypto')

/**
 * Obtiene úna lista de entidades sin filtrar
 */
function getEntidades () {
  let defer = q.defer()
  engine.request('GET', uris.entidades + '/list').then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Crea una nueva entidad en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function createEntidad (object) {
  let defer = q.defer()
  engine.request('POST', uris.entidades, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Actualiza una entidad en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function updateEntidad (object) {
  let defer = q.defer()
  engine.request('PUT', uris.entidades, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene úna lista de proyectos sin filtrar
 */
function getProyectos () {
  let defer = q.defer()
  engine.request('GET', uris.proyectos + '/list').then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene úna lista de registros de auditoria
 */
function getAuditoria (auto, tabla) {
  let defer = q.defer()
  engine.request('GET', uris.auditoria + '/list', { auto: auto, tabl: tabla }).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Crea un nuev proyecto en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function createProyecto (object) {
  let defer = q.defer()
  engine.request('POST', uris.proyectos, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Actualiza un proyecto en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function updateProyecto (object) {
  let defer = q.defer()
  engine.request('PUT', uris.proyectos, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene úna lista de modulos sin filtrar
 */
function getModulos (proyecto) {
  let defer = q.defer()
  engine.request('GET', uris.modulos + '/list', { proyecto: proyecto }).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Crea un nuevo modulo en el sistema
 * @param {Number} proyecto Numero de la llave del proyecto.
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function createModulo (proyecto, object) {
  let defer = q.defer()
  engine.request('POST', uris.modulos, { proyecto: proyecto }, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Actualiza un modulo en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function updateModulo (object) {
  let defer = q.defer()
  engine.request('PUT', uris.modulos, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene úna lista de personal de una entidad sin filtrar
 */
function getPersonal (entidad) {
  let defer = q.defer()
  engine.request('GET', uris.personal + '/list', { entidad: entidad }).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Crea un nuevo personal en el sistema
 * @param {Number} entidad Numero de la llave de la entidad.
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function createPersonal (entidad, object) {
  let defer = q.defer()
  engine.request('POST', uris.personal, { entidad: entidad }, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Actualiza un personal en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function updatePersonal (object) {
  let defer = q.defer()
  engine.request('PUT', uris.personal, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Crea un nuevo ticket en la base de datos
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function createTicket (user, object) {
  let defer = q.defer()
  engine.request('POST', uris.tickets, { user }, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Crea un nuevo item de un ticket en la base de datos
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function createTicketItem (user, object) {
  let defer = q.defer()
  engine.request('POST', uris.ticketsItems, { user }, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Asigna un usuario a un item
 * @param {Number} user Llave del usuario
 * @param {Number} auto Llave del item a asignar
 * @param {Object} object Objeto para la notificacion de la asignacion
 */
function assignTicketItem (user, auto, object) {
  let defer = q.defer()
  engine.request('PUT', uris.ticketsItems + '/asignar', { user, auto }, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Crea una nueva traza a un item de un ticket en la base de datos
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function createTicketTraza (user, object) {
  let defer = q.defer()
  engine.request('POST', uris.ticketsTrazas, { user }, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Crea un nuevo item de un ticket en la base de datos
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function createAnexo (object) {
  let defer = q.defer()
  engine.request('POST', uris.anexos, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene los datos de un usuario desde la plataforma
 */
function userLogin (usd, pwd) {
  let defer = q.defer()
  engine.request('GET', uris.authUser, { usd, pwd: crypto.GenerateHASH(pwd) }).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene úna lista de usuarios sin filtrar
 */
function getUsuarios () {
  let defer = q.defer()
  engine.request('GET', uris.usuarios + '/list').then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene una lista de anexos
 */
function getAnexos (origen, auto) {
  let defer = q.defer()
  engine.request('GET', uris.anexos + '/elemento', { origen, auto }).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene una lista de actuaciones de un item
 */
function getTicketTrazas (auto) {
  let defer = q.defer()
  engine.request('GET', uris.ticketsTrazas + '/elemento', { auto }).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene los contadores
 * @param {String} usuarios Llaves de usuarios para filtrar los datos
 */
function getTicketContadores (usuarios) {
  let defer = q.defer()
  engine.request('GET', uris.tickets + '/indicadores', { usuarios }).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene una lista de tickets para mostrar en la pagina principal.
 * @param {Number} lim Numero de limite de datos a obtener
 * @param {Number} offs Numero de datos mostrados anteriormente
 * @param {String} consecutivo Consecutivo para filtrar
 * @param {String} fecha1 Fecha de rango inicial para consultar
 * @param {String} fecha2 Fecha de rango final para consultar
 * @param {String} usuarios Llaves de usuarios para filtrar los datos
 * @param {String} entidades Llaves de las entidades para filtrar los datos
 * @param {String} proyecto Llaves de los proyectos para filtrar los datos
 * @param {String} modulos Llaves de los modulos para filtrar los datos
 * @param {String} estados Llaves de los estados para filtrar los datos
 * @param {String} origenes Llaves de los origenes para filtrar
 */
function getTicketsPaginacion (lim, offs, consecutivo, fecha1, fecha2, usuarios, entidades, proyecto, modulos, estados, origenes) {
  let defer = q.defer()
  engine.request('GET', uris.ticketsPaginacion, { lim, offs, consecutivo, fecha1, fecha2, usuarios, entidades, proyecto, modulos, estados, origenes }).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

function getReportTicketsGeneral (consecutivo, fecha1, fecha2, usuarios, entidades, proyecto, modulos, estados, origenes) {
  let defer = q.defer()
  engine.request('GET', uris.reportes + '/generaltickets', { consecutivo, fecha1, fecha2, usuarios, entidades, proyecto, modulos, estados, origenes }).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene úna lista de grupos sin filtrar
 */
function getGrupos () {
  let defer = q.defer()
  engine.request('GET', uris.grupos + '/list').then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene úna lista de grupos sin filtrados por uso y usuario
 */
function getGruposUsuario (usuario, cardinal) {
  let defer = q.defer()
  engine.request('GET', uris.grupos + '/list', { usuario: usuario, cardinal: cardinal }).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Crea un nuevo grupo en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function createGrupo (object) {
  let defer = q.defer()
  engine.request('POST', uris.grupos, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Crea un nuevo permiso en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function createPermiso (object) {
  let defer = q.defer()
  engine.request('POST', uris.configPermisos, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Actualiza un permiso en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function updatePermiso (object) {
  let defer = q.defer()
  engine.request('PUT', uris.configPermisos, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Actualiza un grupo en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function updateGrupo (object) {
  let defer = q.defer()
  engine.request('PUT', uris.grupos, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene úna lista de procedimientos sin filtrar
 */
function getProcedimientos () {
  let defer = q.defer()
  engine.request('GET', uris.configProcedimientos + '/list').then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Obtiene úna lista de permisos sin filtrar
 */
function getPermisos (grupo, procedimiento) {
  let defer = q.defer()
  engine.request('GET', uris.configPermisos + '/list', { grupo: grupo, procedimiento: procedimiento }).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Crea un nuevo usuario en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function createUsuario (object) {
  let defer = q.defer()
  engine.request('POST', uris.usuarios, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Actualiza un usuario en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function updateUsuario (object) {
  let defer = q.defer()
  engine.request('PUT', uris.usuarios, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Crea una nueva relacion entre un usuario y un grupo en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function createRelacionGrupo (object) {
  let defer = q.defer()
  engine.request('POST', uris.configRelaciones, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 * Retira una relacion entre un usuario y un grupo en el sistema
 * @param {Object} object Objeto que contiene opciones del formulario.
 */
function removeRelacionGrupo (object) {
  let defer = q.defer()
  engine.request('DELETE', uris.configRelaciones, null, object).then((rta) => {
    defer.resolve(rta)
  }).catch((err) => {
    defer.reject(err)
  })
  // retornamos la promesa
  return defer.promise
}

/**
 *  Crea un registro de email en la plataforma
 * @param {object} object Objeto que contiene los valores a enviar para la creacion
 */
function createEmailInTickets (object) {
  let defer = q.defer()
  engine.request('POST', uris.ticketsEmails, null, object).then(rta => {
    defer.resolve(rta)
  }).catch(err => {
    defer.reject(err)
  })
  return defer.promise
}

function sendEmailNotify (object) {
  let defer = q.defer()
  engine.request('POST', uris.ticketsNotificar, null, object).then(rta => {
    defer.resolve(rta)
  }).catch(err => {
    defer.reject(err)
  })
  return defer.promise
}

export default {
  getEntidades,
  getProyectos,
  getModulos,
  getPersonal,
  getUsuarios,
  getTicketsPaginacion,
  getAnexos,
  getTicketTrazas,
  getTicketContadores,
  getReportTicketsGeneral,
  getAuditoria,
  getProcedimientos,
  getGrupos,
  getPermisos,
  getGruposUsuario,
  createEntidad,
  createPersonal,
  createProyecto,
  createModulo,
  createTicket,
  createTicketItem,
  createTicketTraza,
  createAnexo,
  createGrupo,
  createPermiso,
  createUsuario,
  createRelacionGrupo,
  createEmailInTickets,
  updateEntidad,
  updatePersonal,
  updateProyecto,
  updateModulo,
  updateGrupo,
  updatePermiso,
  updateUsuario,
  userLogin,
  assignTicketItem,
  removeRelacionGrupo,
  sendEmailNotify
}
