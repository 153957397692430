export class Result {
  /**
   * Crea una nueva instancia del objeto Result
   * @param {string} code Codigo que representa un error
   * @param {object} exception Contenido de exception en caso de que ocurra o puede ser null
   * @param {object} result representa un resultado que puede variar entre null o un objeto
   */
  constructor (code, exception = null, result = null) {
    this.code = code
    this.exception = exception
    this.result = result
    switch (code) {
      case 200:
        this.help = 'TODO HA SIDO EJECUTADO CON ÉXITO.'
        break
      case 400:
        this.help = 'NO SE HAN LOCALIZADO DATOS.'
        break
      case 401:
        this.help = 'HA OCURRIDO UN ERROR INTERNO NO CONTROLADO EN EL SERVIDOR.'
        break
      case 500:
        this.help = 'NO HA SIDO POSIBLE EJECUTAR ESTA PETICION EN EL SERVIDOR.'
        break
    }
    // return this
  }
}
