import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let date = new Date()
let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

export default new Vuex.Store({
  state: {
    displayHeight: 0,
    dialogViewer: '',
    user: null,
    filters: {
      consecutivo: '',
      fechaInicio: firstDay,
      fechaFinal: lastDay,
      usuarios: [],
      entidades: [],
      proyectos: null,
      modulos: [],
      estados: [1, 2, 3, 4, 5],
      origenes: [1, 2, 3, 4, 5, 6]
    },
    filterState: 'idle',
    filterStateMessage: 'Cargando tickets...',
    filterResult: [],
    filterCant: 0,
    filterContadores: {
      todosO: 0,
      todosE: 0,
      asignados: 0,
      cerrados: 0,
      email: 0,
      enProceso: 0,
      fijo: 0,
      fisico: 0,
      movil: 0,
      plataforma: 0,
      registrados: 0,
      resueltos: 0,
      whatsapp: 0
    },
    filterPag: 0,
    ticketEditor: {
      item: { auto: 0 }
    },
    configViewer: 'grupos',
    configDialogViewer: null,
    configDialogData: null
  },
  mutations: {
    DISPLAY_HEIGHT_UPDATE (state, value) {
      state.displayHeight = value
    },
    DIALOG_VIEWER_UPDATE (state, value) {
      state.dialogViewer = value
    },
    USER_UPDATE (state, value) {
      state.user = value
    },
    FILTERS_RESTART (state, value) {
      state.filters = {
        consecutivo: '',
        fechaInicio: firstDay,
        fechaFinal: lastDay,
        usuarios: [],
        entidades: [],
        proyectos: null,
        modulos: [],
        estados: [1, 2, 3, 4, 5],
        origenes: [1, 2, 3, 4, 5, 6]
      }
    },
    FILTERS_UPDATE (state, value) {
      state.filters[value.name] = value.value
    },
    FILTER_STATE_TOGGLE (state, value) {
      state.filterState = state.filterState === 'idle' ? 'load' : 'idle'
      state.filterStateMessage = (value === '' ? 'Cargando tickets' : value)
    },
    FILTER_RESULT_UPDATE (state, value) {
      state.filterResult = value
    },
    FILTER_CANT_UPDATE (state, value) {
      state.filterCant = value
    },
    FILTER_PAG_UPDATE (state, value) {
      state.filterPag = value
    },
    FILTER_CONTADOR_UPDATE (state, value) {
      value.todosO = value.email + value.fijo + value.movil + value.whatsapp + value.plataforma + value.fisico
      value.todosE = value.registrados + value.asignados + value.enProceso + value.resueltos + value.cerrados
      state.filterContadores = value
    },
    TICKET_EDITOR_UPDATE (state, value) {
      state.ticketEditor = value
    },
    TICKET_EDITOR_TRAZA_UPDATE (state, value) {
      state.ticketEditor.traza = value
    },
    CONFIGVIEWER_UPDATE (state, value) {
      state.configViewer = value
    },
    CONFIGDIALOG_DATA (state, value) {
      state.configDialogData = value
    },
    CONFIGDIALOG_VIEWER (state, value) {
      state.configDialogViewer = value
    }
  },
  actions: {
    updateDisplayHeight (context, value) {
      context.commit('DISPLAY_HEIGHT_UPDATE', value)
    },
    updateDialogViewer (context, value) {
      context.commit('DIALOG_VIEWER_UPDATE', value)
      if (value === '') context.commit('TICKET_EDITOR_UPDATE', { item: { auto: 0 } })
    },
    updateUser (context, value) {
      context.commit('USER_UPDATE', value)
    },
    filtersRestart (context) {
      context.commit('FILTERS_RESTART')
    },
    filtersUpdate (context, value) {
      context.commit('FILTERS_UPDATE', value)
    },
    filterStateToggle (context, value = '') {
      context.commit('FILTER_STATE_TOGGLE', value)
    },
    filterResultUpdate (context, value) {
      context.commit('FILTER_RESULT_UPDATE', value)
    },
    filterCantUpdate (context, value) {
      context.commit('FILTER_CANT_UPDATE', value)
    },
    filterPag (context, value) {
      context.commit('FILTER_PAG_UPDATE', value)
    },
    filterContadorUpdate (context, value) {
      context.commit('FILTER_CONTADOR_UPDATE', value)
    },
    ticketEditorUpdate (context, value) {
      context.commit('TICKET_EDITOR_UPDATE', value)
    },
    ticketEditorTrazaUpdate (context, value) {
      context.commit('TICKET_EDITOR_TRAZA_UPDATE', value)
    },
    configViewerUpdate (context, value) {
      context.commit('CONFIGVIEWER_UPDATE', value)
    },
    configDialogDataUpdate (context, value) {
      context.commit('CONFIGDIALOG_DATA', value)
    },
    configDialogDataViewer (context, value) {
      context.commit('CONFIGDIALOG_VIEWER', value)
    }
  },
  getters: {
    verifyUserPermision: (state) => (value) => {
      // value.proceso
      // value.procedimiento
      // value.permiso
      let proceso = state.user.procesos.find(el => el.codigo === value.proceso)
      let procedimiento = proceso.procedimientos.find(el => el.codigo === value.procedimiento)
      return procedimiento[`permiso${value.permiso}`]
    }
  }
})
