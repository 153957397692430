<template>
  <table class="ui small celled padded single line table compact table-auditoria">
    <tbody>
      <tr v-for="item in data" :key="item.auto">
        <td data-label="Accion">{{ item.accion }}</td>
        <td data-label="Fecha">{{ item.fecha }}</td>
        <td data-label="Usuario">{{ item.nombre }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'list-auditoria',
  props: {
    data: {
      type: Array,
      default: () => { return [] }
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-auditoria {
    max-height: 150px!important;
  }
</style>
