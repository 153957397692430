const dev = {
  protocol: 'http',
  disclousure: '://',
  serverprod: 'localhost',
  serverdev: 'localhost',
  port: 44338, // 44376, // 5001 <- in mac
  usePort: true
}

const prod = {
  protocol: 'http',
  disclousure: '://',
  serverprod: 'soporteservice.medisoftcolombia.com',
  serverdev: 'soporteservice.medisoftcolombia.com',
  subfolder: '',
  port: 80, // 5001 <- in mac
  usePort: false
}

export {
  dev,
  prod
}
