<template>
  <div class="ui raised segment tickets-new-container">
    <div class="ui active inverted dimmer" v-if="state === 'load'">
      <div class="ui active slow green double text loader">{{ loadText }}</div>
    </div>
    <div class="ui grid">
      <div class="one wide column">
        <div class="ui left floated animated button red mini" @click.stop="handleCloseForm">
          <div class="hidden content">Cerrar</div>
          <div class="visible content">
            <i class="times icon"></i>
          </div>
        </div>
      </div>
      <div class="fourteen wide column tickets-new-title">
        <h3>
          <i :class="`${actualTicket.medio === '1' ? 'envelope' : actualTicket.medio === '2' ? 'fax' : actualTicket.medio === '3' ? 'mobile alternate' : actualTicket.medio === '4' ? 'whatsapp' : actualTicket.medio === '5' ? 'desktop' : 'file alternate outline'} icon`"></i>
          {{ actualTicket.consecutivo }} (
          <i :class="`${actualTicket.traza.auto === 5 ? 'ban' : actualTicket.traza.auto === 4 ? 'check circle' : actualTicket.traza.auto === 3 ? 'clock circle' : actualTicket.traza.auto === 2 ? 'info circle' : 'registered'} icon`"></i>
          {{ actualTicket.traza.nombre }})
        </h3>
      </div>
      <div class="one wide column">
        <!-- <div class="ui right floated animated button teal" @click.stop="handleValidateForm">
          <div class="hidden content">Guardar</div>
          <div class="visible content">
            <i class="save icon"></i>
          </div>
        </div> -->
      </div>
    </div>
    <div class="ui divider"></div>
    <div :style="{ height: heightForm + 'px', maxHeight: heightForm + 'px', overflow: 'auto' }">
      <div class="ui stacked segment">
        <h5 class="ui center aligned header">
          Detalles <div v-if="this.actualTicket.ticket.notificar === 1" class="ui yellow label"><i class="mail icon"></i> SE ESTAN NOTIFICANDO LAS ACTUACIONES AL CLIENTE </div>
        </h5>
        <p style="font-size: 13px;">
          <span v-if="actualTicket.ticket.descripcion !== '' && actualTicket.ticket.descripcion !== ' '">{{ actualTicket.ticket.descripcion }}</span>
          <br v-if="actualTicket.ticket.descripcion !== '' && actualTicket.ticket.descripcion !== ' '">
          <span v-if="actualTicket.item.descripcion !== '' && actualTicket.item.descripcion !== ' '">{{ actualTicket.item.descripcion }}</span>
          <br v-if="actualTicket.traza.auto > 1">
          <br v-if="actualTicket.traza.auto > 1">
          <span v-if="actualTicket.traza.auto > 1"><span style="font-weight: 800;">ASIGNADO A: </span>{{ `[${actualTicket.usuario.iniciales}] ${actualTicket.usuario.nombres} ${actualTicket.usuario.apellidos}` }}</span>
          <br v-if="actualTicket.ticket.notificar === 1">
          <br v-if="actualTicket.ticket.notificar === 1">
        </p>
        <div v-if="actualTicket.ticket.notificar === 1">
          <h5>Notificando actuaciones a:</h5>
          <div class="ui label" v-for="email in actualTicket.ticket.emails" :key="email.auto">
            <i class="mail icon"></i> <span><strong>{{ email.nombre }}</strong>{{ ' <' + `${email.mail}>` }}</span>
          </div>
        </div>
        <list-anexos v-if="anexosTicket.length > 0" :items="anexosTicket" :editable="false"/>
        <list-anexos v-if="anexosItem.length > 0" :items="anexosItem" :editable="false"/>
      </div>
      <list-trazas :elemento="actualTicket.item.auto" :ticket="actualTicket"/>
    </div>
  </div>
</template>

<script>
import ListAnexos from './ListAnexos'
import ListTrazas from './ListTrazas'
import q from 'q'
export default {
  name: 'tickets-editor',
  components: {
    ListAnexos,
    ListTrazas
  },
  computed: {
    heightForm () {
      return this.$store.state.displayHeight - 100
    },
    actualTicket () {
      return this.$store.state.ticketEditor
    }
  },
  data () {
    return {
      state: 'idle',
      loadText: 'Un momento, estamos cargando los datos del Ticket',
      anexosTicket: [],
      anexosItem: []
    }
  },
  mounted () {
    // Inicializacion de datos
    this.state = 'load'
    let actions = [this.consultAnexos(1, this.actualTicket.ticket.auto, 'anexosTicket'), this.consultAnexos(2, this.actualTicket.ticket.auto, 'anexosItem')]
    Promise.all(actions).then((value) => {
      this.state = 'idle'
    }).catch(() => {
      this.state = 'idle'
    })
  },
  methods: {
    handleCloseForm () {
      this.$store.dispatch('updateDialogViewer', '')
      this.$root.$children[0].applyFilther()
    },
    consultAnexos (origen, auto, set) {
      let defer = q.defer()
      this.$soportecore.api.source.getAnexos(origen, auto).then((rta) => {
        this[set] = rta.result
        defer.resolve(1)
      }).catch((err) => {
        if (err.code !== 400) $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar obtener los anexos` })
        console.log(err)
        defer.reject()
      })
      return defer.promise
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/soporte-variables.scss';
  .tickets-new-container {
    background: $--background-primary-color!important;
    padding: 20px!important;
    height: 100%;
  }
  .tickets-new-title {
    text-align: center;
    opacity: 0.4;
  }
  .tickets-new-form {
    padding-left: 10px;
    padding-right: 10px;
  }
</style>
