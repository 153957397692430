<template>
  <div class="items-table-container">
    <tickets-item-new v-if="state === ('new' || 'edit')" :personas="personas" :entidad="entidad()" :cancel-event="closeNewDialog" :save-event="saveNewDialog" :value="value2Edit"/>
    <table v-if="state === 'idle' && data.length > 0" class="ui celled small padded single line table items-table-container">
      <thead>
        <tr>
          <th>Proyecto</th>
          <th>Modulo</th>
          <th>Funcionario</th>
          <th class="one wide">Anexos</th>
          <th class="two wide">
            <div :class="`mini ui ${editorLoad === 'load' ? 'loading' : ''} primary button`" @click="handleNewElement">
              AGREGAR
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.auto">
          <td data-label="Proyecto">
            {{ item.proyecto.nombre }}
            <div :class="`ui basic ${item.tipo === 1 ? 'red' : item.tipo === 2 ? 'green' : item.tipo === 3 ? 'orange' : 'teal'} label label-no-borderer`">
              <i :class="`${item.tipo === 1 ? 'bug' : item.tipo === 2 ? 'file upload' : item.tipo === 3 ? 'external link square alternate' : item.tipo === 4 ? 'upload' : item.tipo === 5 ? 'user astronaut' : item.tipo === 6 ? 'book' : 'podcast'} icon`"></i>
              {{ item.tipo === 1 ? 'BUG' : item.tipo === 2 ? 'MEJORA' : item.tipo === 3 ? 'NUEVA FUNCION' : item.tipo === 4 ? 'ACTUALIZACION' : item.tipo === 5 ? 'SOPORTE' : item.tipo === 6 ? 'CAPACITACION' : 'OTROS' }}
            </div>
          </td>
          <td data-label="Modulo">{{ item.modulo.nombre }}</td>
          <td data-label="Funcionario">{{ item.personal.nombre }}</td>
          <td data-label="Anexos">
            <i class="file icon"></i>
            {{ item.anexos.length }}
          </td>
          <td data-label="">
            <div class="ui small basic icon buttons">
              <div class="ui button" @click="handleEditElement(item)"><i class="edit icon"></i></div>
              <div class="ui button" @click="handleDeleteElement(item)"><i class="trash alternate icon"></i></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="state === 'idle' && data.length < 1" class="ui placeholder segment">
      <div class="ui icon header">
        <i class="exclamation triangle icon"></i>
        No existen items en este ticket, recuerde que todo ticket debe tener al menos un item
      </div>
      <div class="ui teal labeled icon button" @click="handleNewElement">
        Añadir item al ticket
        <i class="add icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
import TicketsItemNew from './TicketsItemNew'
export default {
  name: 'items-table',
  components: { TicketsItemNew },
  props: {
    type: {
      type: String,
      default: 'normal'
    },
    entidad: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      state: 'idle',
      data: [],
      personas: [],
      editorLoad: 'idle',
      value2Edit: null
    }
  },
  methods: {
    openNewDialog () {
      this.editorLoad = 'load'
      let ent = this.entidad()
      if (ent !== null) {
        this.$soportecore.api.source.getPersonal(ent.auto).then((rta) => {
          this.personas = rta.result
          this.state = 'new'
          this.editorLoad = 'idle'
        }).catch((err) => {
          if (err.code === 400) {
            this.personas = []
            this.state = 'new'
            this.editorLoad = 'idle'
            $('body').toast({ class: 'warning', position: 'bottom left', displaytime: 8000, message: 'No existe personal en el hospital seleccionado, recuerde crear alguno para poder crear el item satisfactoriamente.' })
          }
        })
      } else {
        $('body').toast({ class: 'error', position: 'bottom left', message: 'Antes de añadir items debe seleccionar una entidad solicitante primero.' })
      }
    },
    closeNewDialog () {
      this.state = 'idle'
    },
    saveNewDialog (data) {
      if (!data.auto) {
        data.auto = this.data.length + 1
        this.data.push(data)
      }
      this.state = 'idle'
    },
    handleDeleteElement (item) {
      this.data = this.data.filter(element => element.auto !== item.auto)
    },
    handleEditElement (item) {
      this.value2Edit = item
      this.openNewDialog()
    },
    handleNewElement (item) {
      this.value2Edit = null
      this.openNewDialog()
    },
    handleListItems () {
      return this.data
    }
  }
}
</script>
