<template>
  <div class="item">
    <div class="right floated content">
      <div class="ui positive compact mini button" @click="handleCreatePopUp">VER</div>
      <div v-if="editable" class="ui negative compact mini button" @click="handleDeleteEvent">RETIRAR</div>
    </div>
    <i :class="`large ${icon} middle aligned icon`"></i>
    <div class="content">
      <div class="header">{{ item.nombre }}</div>
      <div class="description">{{ item.path }} -- {{ item.sizeShow }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'item-anexo',
  computed: {
    icon () {
      let extension = ''
      let icon = ''
      if (this.item.nombre !== undefined) {
        extension = this.item.nombre.split('.').pop()
      }
      switch (extension) {
        case 'pdf':
          icon = 'file pdf outline'
          break
        case 'xls':
          icon = 'file excel outline'
          break
        case 'xlsx':
          icon = 'file excel outline'
          break
        case 'doc':
          icon = 'file word outline'
          break
        case 'docx':
          icon = 'file word outline'
          break
        case 'ppt':
          icon = 'file powerpoint outline'
          break
        case 'pptx':
          icon = 'file powerpoint outline'
          break
        case 'png':
          icon = 'file image outline'
          break
        case 'jpg':
          icon = 'file image outline'
          break
        case 'jpeg':
          icon = 'file image outline'
          break
        case 'bmp':
          icon = 'file image outline'
          break
        case 'zip':
          icon = 'file archive outline'
          break
        case 'rar':
          icon = 'file archive outline'
          break
        case 'csv':
          icon = 'file alternate outline'
          break
        case 'txt':
          icon = 'file alternate outline'
          break
        default:
          icon = 'file outline'
          break
      }
      return icon
    }
  },
  props: {
    item: {
      type: Object
    },
    deleteEvent: {
      type: Function,
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleDeleteEvent () {
      if (this.deleteEvent !== null) this.deleteEvent(this.item)
    },
    handleCreateBlob () {
      let binaryString = window.atob(this.item.blob)
      let len = binaryString.length
      let bytes = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      return new Blob([bytes.buffer], { type: this.item.mimme })
    },
    handleCreatePopUp () {
      let file = this.handleCreateBlob()
      let fileURL = URL.createObjectURL(file)
      let newwindow = window.open(fileURL, `Documento Anexo: ${this.item.nombre}`, 'height=650, width=650')
      newwindow.document.title = `Documento Anexo: ${this.item.nombre}`
      if (window.focus) { newwindow.focus() }
    }
  }
}
</script>
