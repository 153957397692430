// Librerias de apoyo
const unirest = require('unirest')
const q = require('q')
const objects = require('./objects')
// Define si esta en produccion
const production = process.env.NODE_ENV !== 'development'
// configuracion del servidor del API en desarrollo
const configDev = require('./connector').dev
// configuracion del servidor del API en produccion
const configProd = require('./connector').prod

/**
 * Envia una solicitud al servidor siguiendo los parametros
 * @param {String} method Especifica en mayuscula el metodo a utilizar
 * @param {String} url Uri de la lista de uris para utilizar
 * @param {Object} params objeto JSON que contiene los parametros de la solicitud con sus valores
 * @param {String} body contenido de la solicitud en formato JSON
 */
export function request (method, url, params = null, body = null) {
  // inicia el manejador de promesas
  let defer = q.defer()
  // creamos el requerimiento
  // dependiendo del tipo de configuracion
  let req
  // procesos
  if (production === true) {
    req = unirest(method, configProd.protocol + configProd.disclousure + configProd['server' + 'dev'] + (configProd.usePort ? (':' + configProd.port) : '') + configProd.subfolder + url)
  } else {
    req = unirest(method, configDev.protocol + configDev.disclousure + configDev['server' + 'dev'] + (configDev.usePort ? (':' + configDev.port) : '') + url)
  }
  req.timeout(84000)
  // añadimos los headers por defecto
  let headers = {}
  // let headers = {'Token': token}
  // Definimos el Header de la sesion
  // if (sesion !== null) {
  //   headers = Object.assign({}, headers, {'Sesion': sesion})
  // }
  // Definimos el Header del body
  if (body !== null) {
    headers = Object.assign({}, headers, { 'Content-Type': 'application/json' })
  }
  // // añadimos los headers
  req.headers(headers)
  // añadimos los parametros de la solicitud
  if (params !== null) {
    req.query(params)
  }
  // actualizacion  de datos en el store
  // store.commit('ACTUAL_PROCCES', { state: 'process', method: method, url: req.options.url, action: 'Solicitando datos...', type: method === 'GET' ? '' : method === 'POST' ? 'success' : method === 'PUT' ? 'warning' : method === 'DELETE' ? 'danger' : 'info', color: method === 'GET' ? '#52A4F8' : method === 'POST' ? '#7EBB4D' : method === 'PUT' ? '#DEA354' : method === 'DELETE' ? '#E77A79' : '#909398' })
  // enviamos el requerimiento
  if (body !== null) {
    req.send(body)
  } else {
    req.send()
  }
  // callback al finalizar procesamiento
  req.end((res) => {
    if (res.error) {
      defer.reject(new objects.Result(500, res.error))
    }
    let result = new objects.Result(res.body.code, res.body.exception, res.body.result)
    if (!production) if (result.code !== 200) console.log(`Excepcion Engine API ${url} :: ${result.code}`, result.exception)
    if (result.code !== 200) defer.reject(result)
    else if (result.code === 200) defer.resolve(result)
  })
  // retornamos la promesa
  return defer.promise
}
