<template>
  <div v-show="showAddButton === true || anexos.length > 0" class="anexos-container">
    <div class="ui relaxed divided list anexos-list-container">
      <item-anexo v-for="item in anexos" :key="item.key" :item="item" :delete-event="handleDeleteElement" :editable="editable"/>
    </div>
    <div v-if="editable && showAddButton" class="fluid ui button" @click.stop="openInput">Agregar Anexos</div>
    <input v-show="false" ref="fileinput" class="file-input" type="file" @change="changeInput">
  </div>
</template>

<script>
import ItemAnexo from './ItemAnexo'
export default {
  name: 'list-anexos',
  props: {
    items: {
      type: Array,
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    },
    showAddButton: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ItemAnexo
  },
  data () {
    return {
      anexos: [],
      someData: null
    }
  },
  mounted () {
    if (this.items !== null) {
      this.handleRefreshRemoteElements()
    }
  },
  methods: {
    changeInput (e) {
      if (e.target.files.length > 0) {
        this.someData = e.target.files[0]
        if (e.target.files[0].size > 5242880) {
          $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `El archivo seleccionado excede el limite de carga el cual es 5MB` })
        } else {
          let reader = new FileReader()
          reader.onload = () => {
            this.anexos.push({
              auto: this.anexos.length + 1,
              nombre: e.target.files[0].name,
              path: 'SIN CARGAR',
              mimme: e.target.files[0].type,
              state: 'new',
              blob: reader.result.split(',').pop(),
              size: e.target.files[0].size,
              sizeShow: this.$soportecore.miscelanius.humanFileSize(e.target.files[0].size, true)
            })
          }
          reader.readAsDataURL(this.someData)
        }
      }
    },
    openInput () {
      this.$refs['fileinput'].value = ''
      this.$refs['fileinput'].click()
    },
    getElements () {
      return this.anexos
    },
    handleDeleteElement (element) {
      this.anexos = this.anexos.filter(anexo => anexo.auto !== element.auto)
    },
    handleRefreshRemoteElements () {
      this.items.forEach(item => {
        this.anexos.push({
          auto: this.anexos.length + 1,
          nombre: item.nombre + item.extension,
          path: 'SERVIDOR',
          mimme: item.mimmeType,
          state: 'charged',
          blob: item.contenido
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '../assets/soporte-variables.scss';
.file-input {
  visibility:hidden;
  height: 0px;
}
.anexos-container {
  padding: 5px;
  height: auto!important;
  background: $--background-secondary-color;
  border: 1px solid $--border-primary-color;
  border-radius: 5px;
  margin-bottom: 15px;
}
.anexos-list-container {
  margin: 0px!important;
  height: auto!important;
}
</style>
