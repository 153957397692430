<template>
  <div class="ui grid relaxed">
    <!-- Menu de navegacion lateral para version de escritorio -->
    <div class="three wide column" style="padding-top: 20px; padding-left: 30px; padding-right: 5px;">
      <filter-menu/>
    </div>
    <!-- Tabla y filtros -->
    <div class="thirteen wide column" style="padding-top: 20px; padding-left: 5px; padding-right: 30px;">
      <filter-control/>
      <tickets-table-controls/>
      <tickets-table :style="{ height: heightRow + 'px', maxHeight: heightRow + 'px' }"/>
    </div>
  </div>
</template>

<script>
import TicketsTable from '@/components/TicketsTable.vue'
import TicketsTableControls from '@/components/TicketsTableControls.vue'
import FilterMenu from '@/components/FilterMenu.vue'
import FilterControl from '@/components/FilterControl.vue'
export default {
  name: 'home',
  computed: {
    heightRow () {
      return this.$store.state.displayHeight - 200
    }
  },
  components: {
    TicketsTable,
    FilterMenu,
    FilterControl,
    TicketsTableControls
  },
  data () {
    return {
      stateViewers: 'tickets-table'
    }
  },
  mounted () {
    this.getMounted()
  },
  methods: {
    newTicket () {
      this.stateViewers = 'tickets-new'
    },
    returnTable () {
      this.stateViewers = 'tickets-table'
    },
    saveTicket () {
      if (this.$refs['ticketsNew'].handleValidateForm()) {
        this.$refs['ticketsNew'].handleSaveForm(() => {
          this.returnTable()
        })
      }
    },
    getMounted () {
      console.log(this.$store.state)
      if (this.$route.query.ticketid) {
        this.$soportecore.api.source.getTicketsPaginacion(18, 0, this.$route.query.ticketid, '1999-01-01', '1999-01-01', '', '', '', '', '1,2,3,4,5', '1,2,3,4,5,6').then((rta) => {
          if (rta.result.contenido[0].consecutivo === this.$route.query.ticketid) {
            this.$store.dispatch('ticketEditorUpdate', rta.result.contenido[0])
            this.$store.dispatch('updateDialogViewer', 'tickets-editor')
          } else {
            $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `El ticket que desea cargar no existe en su cuenta` })
          }
      }).catch((err) => {
        if (err.code !== 400) $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar obtener los tickets` })
        else $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `El ticket que desea cargar no existe en su cuenta` })
        console.log(err)
      })
      }
    }
  }
}
</script>

<style lang="scss">
  .home-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
</style>
