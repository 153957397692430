<template>
  <div class="ui stacked segment">
    <div class="ui active inverted dimmer" v-if="state === 'load'">
      <div class="ui active slow green double text loader">{{ loadText }}</div>
    </div>
    <h5 class="ui center aligned header">
      Actuaciones
      <div style="border: 0px;" :class="`ui ${ticketState === 'open' ? 'green' : 'red'} basic horizontal label`">"{{ timeOpen }}"</div>
    </h5>
    <div class="ui items" v-if="state === 'idle'">
      <traza-new v-if="ultimaTraza < 4" :usuarios="usuarios" :ultima-traza="ultimaTraza" :save-call-back="refreshInfo" :elemento="elemento" :ticket="ticket"/>
      <item-traza v-for="actuacion in actuaciones" :key="actuacion.auto" :elemento="actuacion" />
    </div>
  </div>
</template>

<script>
import ItemTraza from './ItemTraza.vue'
import TrazaNew from './TrazaNew.vue'
import q from 'q'
import moment from 'moment'
export default {
  name: 'list-trazas',
  components: {
    ItemTraza,
    TrazaNew
  },
  props: {
    elemento: {
      type: Number
    },
    ticket: {
      type: Object
    }
  },
  data () {
    return {
      actuaciones: [],
      usuarios: [],
      timeOpen: '',
      ticketState: 'open',
      ultimaTraza: 0,
      state: 'load',
      loadText: 'Un momento, estamos cargando las actuaciones del Ticket'
    }
  },
  mounted () {
    this.refreshInfo()
  },
  methods: {
    refreshInfo () {
      this.state = 'load'
      let actions = [this.chargeActuaciones(), this.chargeUsuarios()]
      Promise.all(actions).then((value) => {
        this.updateAdditionalActuaciones()
      }).catch((err) => {
        console.log(err)
      })
    },
    chargeActuaciones () {
      let defer = q.defer()
      this.$soportecore.api.source.getTicketTrazas(this.elemento).then((rta) => {
        this.actuaciones = rta.result
        defer.resolve(1)
      }).catch((err) => {
        if (err.code !== 400) $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar obtener las actuaciones` })
        console.log(err)
        defer.reject(1)
      })
      return defer.promise
    },
    chargeUsuarios () {
      let defer = q.defer()
      this.$soportecore.api.source.getUsuarios().then((rta) => {
        this.usuarios = rta.result
        defer.resolve(2)
      }).catch((err) => {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar obtener los usuarios` })
        console.log(err)
        defer.reject(2)
      })
      return defer.promise
    },
    updateAdditionalActuaciones () {
      this.actuaciones.forEach(actuacion => {
        if (actuacion.traza.auto === 2) {
          actuacion.asignado = this.usuarios.filter(x => x.auto === actuacion.key)[0]
        }
      })
      this.ultimaTraza = this.actuaciones[0].traza.auto
      this.$store.dispatch('ticketEditorTrazaUpdate', this.actuaciones[0].traza)
      // Actualizar el tiempo que lleva abierto el ticket
      let state = this.actuaciones[0].traza.auto === 4 || this.actuaciones[0].traza.auto === 5 ? 'Duró ' : 'Abierto desde hace '
      this.ticketState = this.actuaciones[0].traza.auto === 4 || this.actuaciones[0].traza.auto === 5 ? 'end' : 'open'
      let momentInicio = moment(this.actuaciones[this.actuaciones.length - 1].fecha)
      let momentFinal = moment(new Date(Date.now()))
      if (this.ticketState === 'end') {
        momentFinal = moment(this.actuaciones[0].fecha)
      }
      let momentResult = moment.duration(momentFinal.diff(momentInicio), 'milliseconds')
      let months = momentResult.months()
      let days = momentResult.days()
      let hours = momentResult.hours()
      let minutes = momentResult.minutes()
      let seconds = momentResult.seconds()
      if (months > 0) this.timeOpen = `${months} ${months === 1 ? 'Mes' : 'Meses'}`
      else if (months === 0 && days > 0) this.timeOpen = `${days} ${days === 1 ? 'Dia' : 'Dias'}`
      else if (months === 0 && days === 0 && hours > 0) {
        this.timeOpen = `${hours} ${hours === 1 ? 'Hora' : 'Horas'}`
        if (minutes > 0) {
          this.timeOpen = `${this.timeOpen} ${minutes} ${minutes === 1 ? 'Minuto' : 'Minutos'}`
        }
      } else if (months === 0 && days === 0 && hours === 0 && minutes > 0) {
        this.timeOpen = `${minutes} ${minutes === 1 ? 'Minuto' : 'Minutos'}`
        if (seconds > 0) {
          this.timeOpen = `${this.timeOpen} ${seconds} ${seconds === 1 ? 'Segundo' : 'Segundos'}`
        }
      } else {
        this.timeOpen = `${seconds} ${seconds === 1 ? 'Segundo' : 'Segundos'}`
      }
      this.timeOpen = `${state} ${this.timeOpen}`
      this.state = 'idle'
    }
  }
}
</script>
