<template>
  <div class="ui segment">
    <div class="ui active inverted dimmer" v-if="state === 'load'">
      <div class="ui active slow green double text loader">{{ loadText }}</div>
    </div>
    <div class="ui form">
      <div class="field">
        <div :class="`ui ${renderState === 'advance' ? '' : 'mini'} action input`">
          <input ref="searchGeneral" type="text" placeholder="Buscar directamente con el numero de consecutivo..." v-model="consecutivo" @focus="changeMode('input')" @keyup="keyEvent">
          <div :class="`ui ${renderState === 'advance' ? '' : 'mini'} icon button`" @click.stop="changeMode('default')">
            <i :class="`chevron ${renderState === 'advance' ? 'up' : 'down'} icon`"></i>
          </div>
          <div :class="`ui ${renderState === 'advance' ? '' : 'mini'} teal icon button`" @click.stop="applyFilther">
            <i class="search icon"></i>
          </div>
        </div>
      </div>
      <div class="two fields" v-show="renderState === 'advance'">
        <div class="field">
          <div class="two fields">
            <div class="field">
              <label>Rango de fecha inicial</label>
              <div class="ui calendar" id="rangestartfilther">
                <div class="ui input left icon">
                  <i class="calendar icon"></i>
                  <input type="text" placeholder="Inicio" v-model="form.fechainicioShow">
                </div>
              </div>
            </div>
            <div class="field">
              <label>Rango de fecha final</label>
              <div class="ui calendar" id="rangeendfilther">
                <div class="ui input left icon">
                  <i class="calendar icon"></i>
                  <input type="text" placeholder="Final" v-model="form.fechafinalShow">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <label>Usuario</label>
          <div :class="`ui ${lockUsers === 0 ? 'disabled' : ''} clearable selection dropdown`" id="dropdownusuarios">
            <input type="hidden" v-model="form.usuario">
            <i class="dropdown icon"></i>
            <div class="default text">Seleccione un usuario o varios para filtrar por ellos</div>
            <div class="menu">
              <div v-for="usuario in usuarios" :key="usuario.auto" class="item" :data-text="`${usuario.nombres} ${usuario.apellidos}`" :data-value="usuario.auto">
                 <img class="ui avatar image" :src="`data:${'image/png'};base64,${usuario.imagen}`">
                 {{ `${usuario.nombres} ${usuario.apellidos}` }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fields" v-show="renderState === 'advance'">
        <div class="eight wide field">
          <label>Entidad</label>
          <div class="ui clearable multiple selection dropdown" id="dropdownentidad">
            <input type="hidden">
            <i class="dropdown icon"></i>
            <div class="default text">Seleccione una entidad o varias para filtrar por ellas</div>
            <div class="menu">
              <div v-for="entidad in entidades" :key="entidad.auto" class="item" :data-text="entidad.nombre" :data-value="entidad.auto">
                {{ entidad.nombre }}
              </div>
            </div>
          </div>
        </div>
        <div class="four wide field">
          <label>Proyectos</label>
          <div class="ui clearable search selection dropdown" id="dropdownproyecto">
            <input type="hidden">
            <i class="dropdown icon"></i>
            <div class="default text">Seleccione un proyecto</div>
            <div class="menu">
              <div v-for="proyecto in proyectos" :key="proyecto.auto" class="item" :data-text="proyecto.nombre" :data-value="proyecto.auto">
                {{ proyecto.nombre }}
              </div>
            </div>
          </div>
        </div>
        <div class="four wide field">
          <label>Modulos</label>
          <div class="ui clearable multiple selection dropdown" id="dropdownmodulo">
            <input type="hidden">
            <i class="dropdown icon"></i>
            <div class="default text">Seleccione un modulo</div>
            <div class="menu">
              <div v-for="modulo in modulos" :key="modulo.auto" class="item" :data-text="modulo.nombre" :data-value="modulo.auto">
                {{ modulo.nombre }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field" v-show="renderState === 'basic'">
        <!-- consecutivo -->
        <div v-if="filtros.consecutivo !== ''" class="border-label ui basic label">
          {{ filtros.consecutivo }}
        </div>
        <!-- rango de fechas -->
        <div class="border-label ui basic label">
          <i class="calendar icon"></i>
          {{ filtros.fechaInicio.toLocaleDateString() }} - {{ filtros.fechaFinal.toLocaleDateString() }}
          <!-- <i class="close icon"></i> -->
        </div>
        <!-- Usuarios -->
        <div v-for="usuario in usuariosSeleccionados" :key="usuario.auto" class="border-label ui basic label">
          <i class="user icon"></i>
          {{ usuario.nombres }}
          <!-- <i class="close icon"></i> -->
        </div>
        <!-- Entidades -->
        <div v-for="entidad in entidadesSeleccionadas" :key="entidad.auto" class="border-label ui basic label">
          <i class="university icon"></i>
          {{ entidad.nombre }}
          <!-- <i class="close icon"></i> -->
        </div>
        <!-- Proyectos -->
        <div v-for="proyecto in proyectosSeleccionados" :key="proyecto.auto" class="border-label ui basic label">
          <i class="cubes icon"></i>
          {{ proyecto.nombre }}
          <!-- <i class="close icon"></i> -->
        </div>
        <!-- Modulos -->
        <div v-for="modulo in modulosSeleccionados" :key="modulo.auto" class="border-label ui basic label">
          <i class="cube icon"></i>
          {{ modulo.nombre }}
          <!-- <i class="close icon"></i> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from '../libs/helpers.js'
import q from 'q'
let date = new Date()
let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
export default {
  name: 'filter-control',
  data () {
    return {
      state: 'idle',
      renderState: 'basic',
      loadText: 'Un momento por favor...',
      consecutivo: '',
      form: {
        fechainicioShow: '',
        fechafinalShow: '',
        usuario: 'LUIS CARLOS VASQUEZ TORO'
      },
      proyecto: 0,
      usuarios: [],
      entidades: [],
      proyectos: [],
      modulos: [],
      entidadesSeleccionadas: [],
      usuariosSeleccionados: [],
      proyectosSeleccionados: [],
      modulosSeleccionados: [],
      lockUsers: 0
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    filtros () {
      return this.$store.state.filters
    }
  },
  mounted () {
    // Definicion de pantalla de espera
    this.state = 'load'
    // Inicializacion de componentes
    $('#rangestartfilther').calendar({
      type: 'date',
      initialDate: firstDay,
      text: helper.calendarHelper,
      endCalendar: $('#rangeendfilther'),
      onChange: (value, text, choice) => {
        // console.log({value, text, choice})
        if (text === '') this.$store.dispatch('filtersUpdate', { name: 'fechaInicio', value: null })
        else this.$store.dispatch('filtersUpdate', { name: 'fechaInicio', value: value })
        this.form.fechainicioShow = text
      }
    })
    $('#rangeendfilther').calendar({
      type: 'date',
      initialDate: lastDay,
      text: helper.calendarHelper,
      startCalendar: $('#rangestartfilther'),
      onChange: (value, text, choice) => {
        if (text === '') this.$store.dispatch('filtersUpdate', { name: 'fechaFinal', value: null })
        else this.$store.dispatch('filtersUpdate', { name: 'fechaFinal', value: value })
        this.form.fechafinalShow = text
      }
    })
    $('#dropdownusuarios').dropdown({
      onChange: (value, text, $choice) => {
        this.$store.dispatch('filtersUpdate', { name: 'usuarios', value: JSON.parse(`[${value}]`) })
      }
    })
    $('#dropdownentidad').dropdown({
      onChange: (value, text, $choice) => {
        this.$store.dispatch('filtersUpdate', { name: 'entidades', value: JSON.parse(`[${value}]`) })
      }
    })
    $('#dropdownproyecto').dropdown({
      onChange: (value, text, $choice) => {
        this.$store.dispatch('filtersUpdate', { name: 'proyectos', value: value === '' ? null : value })
        this.proyecto = value
        if (value !== '') this.chargeModulos()
        else {
          this.modulos = []
          // this.$store.dispatch('filtersUpdate', { name: 'modulos', value: [] })
          $('#dropdownmodulo').dropdown('clear')
        }
      }
    })
    $('#dropdownmodulo').dropdown({
      onChange: (value, text, $choice) => {
        this.$store.dispatch('filtersUpdate', { name: 'modulos', value: JSON.parse(`[${value}]`) })
      }
    })
    // Obtencion de permisos de administrador de usuario
    this.lockUsers = this.$store.getters.verifyUserPermision({
      proceso: 'HOME',
      procedimiento: 'HOME_INDEX',
      permiso: 'Administrador'
    })
    // Inicilizacion de datos
    let actions = [this.chargeUsuarios(), this.chargeEntidades(), this.chargeProyectos()]
    Promise.all(actions).then((value) => {
      this.state = 'idle'
      this.applyFilther()
    })
  },
  methods: {
    chargeUsuarios () {
      let defer = q.defer()
      this.$soportecore.api.source.getUsuarios().then((rta) => {
        this.usuarios = rta.result
        this.setDefaultUser()
        defer.resolve(1)
      }).catch((err) => {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar obtener los usuarios` })
        console.log(err)
        defer.reject()
      })
      return defer.promise
    },
    chargeEntidades () {
      let defer = q.defer()
      this.$soportecore.api.source.getEntidades().then((rta) => {
        this.entidades = rta.result
        defer.resolve(2)
      }).catch((err) => {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar obtener las entidades` })
        console.log(err)
        defer.reject()
      })
      return defer.promise
    },
    chargeProyectos () {
      let defer = q.defer()
      this.$soportecore.api.source.getProyectos().then((rta) => {
        this.proyectos = rta.result
        defer.resolve(3)
      }).catch((err) => {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar obtener los proyectos` })
        console.log(err)
        defer.reject()
      })
      return defer.promise
    },
    chargeModulos () {
      this.state = 'load'
      this.$soportecore.api.source.getModulos(this.proyecto).then((rta) => {
        this.modulos = rta.result
        this.state = 'idle'
      }).catch((err) => {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar obtener los modulos` })
        this.state = 'idle'
        console.log(err)
      })
    },
    changeMode (origin = 'default') {
      if (this.renderState === 'basic') this.renderState = 'advance'
      else {
        if (origin === 'default') this.renderState = 'basic'
      }
    },
    setDefaultUser () {
      if (this.lockUsers === 0) this.$store.dispatch('filtersUpdate', { name: 'usuarios', value: JSON.parse(`[${this.user.auto}]`) })
    },
    keyEvent (e) {
      if (e.key === 'Enter') this.applyFilther()
    },
    updateSelects () {
      this.entidadesSeleccionadas = [] // se limpian
      this.usuariosSeleccionados = [] // se limpian
      this.proyectosSeleccionados = [] // se limpian
      this.modulosSeleccionados = [] // se limpian
      // Selecccionamos el usuario
      this.usuarios.forEach(element => {
        this.filtros.usuarios.forEach(filtro => {
          if (element.auto === filtro) {
            this.usuariosSeleccionados.push({
              auto: element.auto,
              nombres: element.nombres + ' ' + element.apellidos
            })
          }
        })
      })
      if (this.usuariosSeleccionados.length === 0) {
        this.usuariosSeleccionados.push({
          auto: 0,
          nombres: 'TODOS'
        })
      }
      // Seleccionamos las entidades
      this.entidades.forEach(element => {
        this.filtros.entidades.forEach(filtro => {
          if (element.auto === filtro) this.entidadesSeleccionadas.push(element)
        })
      })
      // Seleccionamos los proyectos
      if (this.filtros.proyectos !== null) {
        this.proyectos.forEach(element => {
          if (element.auto === parseInt(this.filtros.proyectos)) this.proyectosSeleccionados.push(element)
        })
      }
      // Seleccionamos los modulos
      this.modulos.forEach(element => {
        this.filtros.modulos.forEach(filtro => {
          if (element.auto === filtro) {
            this.modulosSeleccionados.push(element)
          }
        })
      })
    },
    applyFilther () {
      this.$root.$children[0].applyFilther()
      this.renderState = 'basic'
      this.$refs['searchGeneral'].blur()
      this.updateSelects()
    }
  },
  watch: {
    consecutivo: function (val) {
      this.$store.dispatch('filtersUpdate', { name: 'consecutivo', value: val })
    }
  }
}
</script>

<style lang="css">
.border-label {
  border: 0px!important;
  opacity: 0.5;
  cursor: default;
}
</style>
