<template>
  <div class="ui raised segment">
    <input v-show="false" ref="fileinput" class="file-input" type="file" @change="handleInput">
    <div class="ui active inverted dimmer" v-if="state === 'load'">
      <div class="ui active slow green double text loader">{{ loadText }}</div>
    </div>
    <form class="ui form">
      <h4 class="ui dividing header">{{ `${type === 'new' ? 'Crear nuevo' : 'Editar '} Personal` }}</h4>
      <div class="field" style="text-align: center;">
        <label>Imagen de perfil</label>
        <div v-if="form.imagen === ''"><img class="profile-image" src="../assets/userDesc.png"></div>
        <div v-if="form.imagen !== ''"><img class="profile-image" :src="`data:${'image/png'};base64,${form.imagen}`"></div>
        <div><div class="mini ui teal button" @click.stop="openInput">Cambiar imagen</div></div>
      </div>
      <div class="field">
        <div class="fields">
          <div class="eight wide field">
            <label>Nombres</label>
            <input v-model="form.nombres" type="text" name="nombres" placeholder="Primer y segundo nombre">
          </div>
          <div class="eight wide field">
            <label>Apellidos</label>
            <input v-model="form.apellidos" type="text" name="apellidos" placeholder="Primer y segundo apellido">
          </div>
        </div>
      </div>
      <div class="field">
        <div class="fields">
          <div class="eight wide field">
            <label>Identificacion</label>
            <input v-model="form.identificacion" type="text" name="identificacion" placeholder="Numero de identificacion del usuario">
          </div>
          <div class="eight wide field">
            <label>Email</label>
            <input v-model="form.usd" type="text" name="usd" placeholder="Correo electronico">
          </div>
        </div>
      </div>
      <div class="field">
        <div class="fields">
          <div class="eight wide field">
            <label>Grupos disponibles</label>
            <div class="ui middle aligned divided list">
              <div v-for="item in gruposSinUsar" :key="item.auto" class="item">
                <div class="right floated content">
                  <div class="ui button blue mini" @click.stop="handleGroupChange('new', item)">Agregar</div>
                </div>
                <div class="content">
                  {{ item.nombre }}
                </div>
              </div>
            </div>
          </div>
          <div class="eight wide field">
            <label>Grupos actuales</label>
            <div class="ui middle aligned divided list">
              <div v-for="item in gruposUsados" :key="item.auto" class="item">
                <div class="right floated content">
                  <div class="ui button blue mini" @click.stop="handleGroupChange('remove', item)">Retirar</div>
                </div>
                <div class="content">
                  {{ item.nombre }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field" v-if="auditoriaShow && auditoriaData.length > 0">
        <label>Auditoria</label>
        <ListAuditoria :data="auditoriaData"/>
      </div>
      <div class="ui submit buttons">
        <div class="ui button" @click.stop="handleClose">Cancelar</div>
        <div class="or" data-text="o"></div>
        <div class="ui positive button" @click.stop="handleSave">Guardar</div>
      </div>
    </form>
  </div>
</template>

<script>
import ListAuditoria from './ListAuditoria'
const crypto = require('../libs/api/crypto.js')
export default {
  name: 'usuario-new',
  props: {
    type: {
      type: String,
      default: 'new'
    },
    entidad: {
      type: Object,
      default: null
    },
    closeEvent: {
      type: Function,
      default: null
    },
    reloadEvent: {
      type: Function,
      default: null
    },
    showEntidadSelector: {
      type: Boolean,
      default: false
    },
    showAuditoria: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    profile () {
      return this.$store.state.user
    },
    dataEditor () {
      return this.$store.state.configDialogData
    }
  },
  components: {
    ListAuditoria
  },
  mounted () {
    if (this.dataEditor !== null || this.type !== 'new') {
      this.form = this.dataEditor
      this.chargeAuditoria(this.form.auto, 'STUSUARIOS')
      this.chargeGrupos()
      this.auditoriaShow = true
    } else {
      this.form = {
        auto: 0,
        nombres: '',
        apellidos: '',
        identificacion: '',
        imagen: '',
        usd: ''
      }
      this.auditoriaShow = false
    }
  },
  data () {
    return {
      state: 'idle',
      loadText: 'Un momento',
      form: {
        auto: 0,
        nombres: '',
        apellidos: '',
        identificacion: '',
        imagen: '',
        usd: ''
      },
      gruposUsados: [],
      gruposSinUsar: [],
      auditoriaState: 'idle',
      auditoriaData: [],
      auditoriaShow: false,
      userAvatar: undefined
    }
  },
  methods: {
    handleInput (e) {
      if (e.target.files.length > 0) {
        let reader = new FileReader()
        reader.onload = () => {
          this.form.imagen = reader.result.split(',').pop()
        }
        reader.readAsDataURL(e.target.files[0])
      }
    },
    handleClose () {
      if (this.closeEvent !== null) this.closeEvent()
      if (this.$parent.$options._componentTag === 'list-selector') this.$parent.handleCloseSlot()
    },
    handleSave () {
      this.state = 'load'
      this.loadText = this.dataEditor === null ? 'Creando usuario...' : 'Actualizando usuario...'
      if (this.dataEditor === null) {
        this.$soportecore.api.source.createUsuario({
          identificacion: this.form.identificacion,
          nombres: this.form.nombres,
          apellidos: this.form.apellidos,
          usd: this.form.usd,
          pwd: crypto.GenerateHASH(this.form.identificacion),
          imagen: this.form.imagen,
          usuario: this.profile.auto
        }).then((rta) => {
          this.state = 'idle'
          this.form.auto = rta.result
          if (this.reloadEvent !== null) this.reloadEvent()
          if (this.$parent.$options._componentTag === 'list-selector') this.$parent.handleCloseSlot(this.form)
        }).catch((err) => {
          console.log(err)
          $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
          this.state = 'idle'
        })
      } else {
        this.$soportecore.api.source.updateUsuario({
          auto: this.dataEditor.auto,
          identificacion: this.form.identificacion,
          nombres: this.form.nombres,
          apellidos: this.form.apellidos,
          usd: this.form.usd,
          imagen: this.form.imagen,
          usuario: this.profile.auto
        }).then((rta) => {
          this.state = 'idle'
          this.form.auto = rta.result
          if (this.reloadEvent !== null) this.reloadEvent()
          if (this.$parent.$options._componentTag === 'list-selector') this.$parent.handleCloseSlot(this.form)
        }).catch((err) => {
          $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
          this.state = 'idle'
        })
      }
    },
    handleGroupChange (action, item) {
      this.state = 'load'
      if (action === 'new') {
        this.$soportecore.api.source.createRelacionGrupo({
          usua: this.dataEditor.auto,
          grupo: item.auto,
          usuario: this.profile.auto
        }).then((rta) => {
          this.state = 'idle'
          this.chargeGrupos()
        }).catch((err) => {
          $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
          this.state = 'idle'
        })
      } else if (action === 'remove') {
        this.$soportecore.api.source.removeRelacionGrupo({
          auto: item.relation,
          usuario: this.profile.auto
        }).then((rta) => {
          this.state = 'idle'
          this.chargeGrupos()
        }).catch((err) => {
          $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
          this.state = 'idle'
        })
      }
    },
    chargeAuditoria (auto, tabla) {
      this.auditoriaState = 'load'
      this.loadText = 'Cargando grupos...'
      this.$soportecore.api.source.getAuditoria(auto, tabla).then((rta) => {
        this.auditoriaData = rta.result
        this.auditoriaState = 'idle'
      }).catch(() => {
        // $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
        this.auditoriaState = 'idle'
      })
    },
    chargeGrupos () {
      this.state = 'load'
      this.$soportecore.api.source.getGruposUsuario(this.dataEditor.auto, 0).then((rta) => {
        this.gruposUsados = rta.result
        this.$soportecore.api.source.getGruposUsuario(this.dataEditor.auto, 1).then((rta) => {
          this.gruposSinUsar = rta.result
          this.state = 'idle'
        }).catch(() => {
          this.state = 'idle'
        })
      }).catch(() => {
        this.state = 'idle'
      })
    },
    openInput () {
      this.$refs['fileinput'].value = ''
      this.$refs['fileinput'].click()
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-image {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 10px;
  background: whitesmoke;
  margin-bottom: 5px;
}
.file-input {
  visibility:hidden;
  height: 0px;
}
</style>
