import api from './api'
import miscelanius from './miscelanius'

const soportecore = {
  install: function (Vue, option) {
    Vue.prototype.$soportecore = {
      api,
      miscelanius
    }
  }
}

export default soportecore
