import '../semantic/dist-theme/semantic.min'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import soportecore from './libs/soportecore'

import PrettyCheckbox from 'pretty-checkbox-vue'

import 'animate.css'
import 'pretty-checkbox/dist/pretty-checkbox.css'
import '../semantic/dist-theme/semantic.min.css'
import './assets/main.scss'

Vue.config.productionTip = false

Vue.use(soportecore)
Vue.use(PrettyCheckbox)

Vue.directive('focus-on-create', {
  inserted: function (el, binding, vnode, oldVnode) {
    document.getElementById(vnode.data.attrs.id).focus()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
