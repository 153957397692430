<template>
  <div class="ui segment">
    <div class="editor-container" v-show="state === 'idle'">
      <div class="editor">
        <!-- MenuBar -->
        <editor-menu-bar :editor="editor">
          <div class="menubar" slot-scope="{ commands }">
            <!-- Selector de Accion -->
            <div id="dropdownaction" class="ui teal floating compact dropdown labeled search icon button">
              <i :class="`${traza === null ? 'world' : traza.icon} icon`"></i>
              <span class="text">Seleccione una accion</span>
              <div class="menu">
                <div class="item" v-for="item in trazasEdit" :key="item.auto" :data-text="item.nombre" :data-value="item.auto">
                  <i :class="`${item.icon} icon`"></i>
                  {{ item.nombre }}
                </div>
              </div>
            </div>
            <!-- Selector de usuario -->
            <div v-show="traza.auto === 2" id="dropdownuser" class="ui teal floating compact dropdown labeled search icon button">
              <i class="user icon"></i>
              <span class="text">Seleccione un usuario</span>
              <div class="menu">
                <div v-for="usuario in usuarios" :key="usuario.auto" class="item" :data-text="`${usuario.nombres} ${usuario.apellidos}`" :data-value="usuario.auto">
                  <img class="ui avatar image" :src="`data:${'image/png'};base64,${usuario.imagen}`">
                  {{ `${usuario.nombres} ${usuario.apellidos}` }}
                </div>
              </div>
            </div>
            <!-- Botones del editor enriquecido -->
            <div class="ui tertiary icon button" @click="commands.bold" >
              <i class="bold icon"></i>
            </div>
            <div class="ui tertiary icon button" @click="commands.italic" >
              <i class="italic icon"></i>
            </div>
            <div class="ui tertiary icon button" @click="commands.strike" >
              <i class="strikethrough icon"></i>
            </div>
            <div class="ui tertiary icon button" @click="commands.underline" >
              <i class="underline icon"></i>
            </div>
            <div class="ui tertiary icon button" @click="commands.code" >
              <i class="code icon"></i>
            </div>
            <div class="ui tertiary icon button" @click="commands.paragraph" >
              <i class="paragraph icon"></i>
            </div>
            <div class="ui tertiary button" @click="commands.heading({ level: 1 })" >
              H1
            </div>
            <div class="ui tertiary button" @click="commands.heading({ level: 2 })" >
              H2
            </div>
            <div class="ui tertiary button" @click="commands.heading({ level: 3 })" >
              H3
            </div>
            <div class="ui tertiary icon button" @click="commands.bullet_list" >
              <i class="list ul icon"></i>
            </div>
            <div class="ui tertiary icon button" @click="commands.ordered_list" >
              <i class="list ol icon"></i>
            </div>
            <div class="ui tertiary icon button" @click="commands.blockquote" >
              <i class="quote right icon"></i>
            </div>
            <div class="ui tertiary icon button" @click="commands.undo" >
              <i class="undo icon"></i>
            </div>
            <div class="ui tertiary icon button" @click="commands.redo" >
              <i class="redo icon"></i>
            </div>
          </div>
        </editor-menu-bar>
        <!-- Contenido -->
        <editor-content ref="editorcontent" class="editor__content" :editor="editor" />
      </div>
    </div>
    <div v-show="state === 'idle'">
      <list-anex ref="listanex" :show-add-button="false"/>
    </div>
    <div class="buttons-area">
      <div v-if="state === 'idle'" class="ui compact blue icon button" @click="handleClickAnex">
        <i class="paperclip icon"></i>
      </div>
      <span v-if="state === 'load'" class="span-text">Almacenando datos...</span>
      <div :class="`positive ${state === 'idle' ? '' : 'elastic loading'} compact ui button`" @click="handleValidaForm">Guardar</div>
    </div>
  </div>
</template>

<script>
import ListAnex from './ListAnexos.vue'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { Blockquote, HardBreak, Heading, OrderedList, BulletList, ListItem, TodoItem, TodoList, Bold, Code, Italic, Link, Strike, Underline, History } from 'tiptap-extensions'
export default {
  name: 'traza-new',
  props: {
    ultimaTraza: {
      type: Number,
      default: 2
    },
    usuarios: {
      type: Array
    },
    saveCallBack: {
      type: Function,
      default: null
    },
    elemento: {
      type: Number
    },
    ticket: {
      type: Object
    }
  },
  computed: {
    profile () {
      return this.$store.state.user
    }
  },
  components: {
    EditorContent,
    EditorMenuBar,
    ListAnex
  },
  data () {
    return {
      state: 'idle',
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new TodoItem(),
          new TodoList(),
          new Bold(),
          new Code(),
          new Italic(),
          new Link(),
          new Strike(),
          new Underline(),
          new History()
        ],
        content: '',
        onUpdate: ({ getHTML }) => {
          this.editorContent = getHTML()
        }
      }),
      trazas: [
        { auto: 2, icon: 'info circle', nombre: 'ASIGNAR', last: [1], requireText: false },
        { auto: 3, icon: 'clock circle', nombre: 'ACTUAR', last: [2, 3], requireText: true },
        { auto: 4, icon: 'check circle', nombre: 'RESOLVER', last: [2, 3], requireText: true },
        { auto: 5, icon: 'ban', nombre: 'CERRAR', last: [2, 3], requireText: true },
        { auto: 2, icon: 'info circle', nombre: 'REASIGNAR', last: [2, 3], requireText: true }
      ],
      anexos: [],
      editorContent: '',
      trazasEdit: [],
      traza: { auto: 0 },
      user: null
    }
  },
  methods: {
    handleClickAnex () {
      this.$refs['listanex'].openInput()
    },
    updateTrazas (val) {
      this.trazasEdit = []
      this.trazas.forEach(traza => {
        if (traza.last.includes(val)) {
          this.trazasEdit.push(traza)
        }
      })
    },
    handleValidaForm () {
      this.anexos = this.$refs['listanex'].getElements()
      // Verificacion de los datos minimos para guardar la traza
      let errors = 0
      if (!this.traza.nombre) {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 9000, message: 'No ha seleccionado una accion' })
        errors++
      }
      if (this.traza.requireText === true && this.editorContent === '') {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 9000, message: 'Esta accion requiere un texto adicional para poder ser almacenada' })
        errors++
      }
      if (this.traza.auto === 2 && this.user === null) {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 9000, message: 'Debe seleccionar un usuario para poder continuar con la asignacion' })
        errors++
      }
      if (errors === 0) this.handleSaveForm()
    },
    handleSaveForm () {
      this.state = 'load'
      // Creacion de la base del nuevo estado
      this.$soportecore.api.source.createTicketTraza(this.profile.auto, {
        ticketItemAuto: this.elemento,
        accionAuto: this.traza.auto,
        key: this.traza.auto === 2 ? this.user.auto : 0,
        respuesta: this.editorContent === '' ? ' ' : this.editorContent
      }).then((rta) => {
        if (this.traza.auto === 2) {
          const notificationObject = {
            userFromName: this.profile.nombresApellidos,
            userFromLink: `http://soporteservice.medisoftcolombia.com/api/usuarios/profile/image?userauto=${this.profile.auto}`,
            userToName: this.user.slack,
            consecutive: this.ticket.consecutivo,
            app: this.ticket.proyecto.nombre,
            module: this.ticket.modulo.nombre,
            content: this.ticket.ticket.descripcion + '\n' + this.ticket.item.descripcion,
            notify: this.ticket.ticket.notificar,
            emails: this.ticket.ticket.emails
          }
          this.$soportecore.api.source.assignTicketItem(this.user.auto, this.elemento, notificationObject).then(() => {
            if (this.anexos.length === 0) if (this.saveCallBack !== null) this.saveCallBack()
          }).catch((err) => {
            $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar asignar el ticket` })
            if (this.anexos.length === 0) if (this.saveCallBack !== null) this.saveCallBack()
          })
        } else {
          if (this.ticket.ticket.notificar === 1) {
            const notificationMail = {
              status: this.traza.auto === 3 ? 'ACTUAR' : this.traza.auto === 4 ? 'RESOLVER' : 'CERRAR',
              content: this.editorContent,
              ticket: this.ticket.consecutivo,
              name: this.ticket.ticket.emails.filter(x => x.modo === 0)[0].nombre,
              to: this.ticket.ticket.emails
            }
            this.$soportecore.api.source.sendEmailNotify(notificationMail).then(() => {
              $('body').toast({ class: 'success', position: 'bottom left', displayTime: 10000, message: 'Se ha enviado una notificacion al cliente' })
            }).catch((err) => {
              if (err.code === undefined) {
                $('body').toast({ class: 'success', position: 'bottom left', displayTime: 10000, message: 'Se ha enviado una notificacion al cliente' })
              } else {
                $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar notificar al cliente` })
              }
            })
          }
        }
        // Creacion de los anexos del estado
        if (this.anexos.length > 0) {
          // Preparar nueva lista de anexos para crear
          let listAnexos = []
          this.anexos.forEach(anexo => {
            listAnexos.push({
              tipo: 3,
              key: rta.result,
              mimmeType: anexo.mimme,
              nombre: anexo.nombre,
              contenido: anexo.blob
            })
          })
          // Se crea un pool de promesas para los anexos del ticket y se espera a que todas finalicen
          let actionAnexos = listAnexos.map(this.$soportecore.api.source.createAnexo)
          // Agregar la accion de la asignacion en caso que haya lugar
          let resultAnexos = Promise.all(actionAnexos)
          resultAnexos.then(respuestasAnexos => {
            if (respuestasAnexos.length !== this.anexos.length) {
              $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: 'Ha ocurrido un error al cargar los anexos, no han sido cargados de forma completa' })
            } else {
              if (this.saveCallBack !== null) this.saveCallBack()
              this.state = 'idle'
            }
          }).catch(err => {
            $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar cargar los anexos` })
          })
        }
        // en caso que sea un estado normal
        if (this.traza.auto !== 2 && this.anexos.length === 0) if (this.saveCallBack !== null) this.saveCallBack()
      }).catch((err) => {
        console.log(err)
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar crear la traza` })
      })
    }
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  mounted () {
    $('#dropdownaction').dropdown({
      onChange: (value, text, $choice) => {
        this.traza = this.trazasEdit.filter(x => x.auto.toString() === value)[0]
      }
    })
    $('#dropdownuser').dropdown({
      onChange: (value, text, $choice) => {
        this.user = this.usuarios.filter(x => x.auto.toString() === value)[0]
      }
    })
    this.updateTrazas(this.ultimaTraza)
  },
  watch: {
    ultimaTraza: function (val) {
      this.updateTrazas(val)
    }
  }
}
</script>

<style lang="scss">
@import '../assets/main.scss';
.buttons-area {
  text-align: right;
}
.span-text {
  font-size: 14.5px;
  font-weight: 800;
  margin-right: 10px;
}
</style>
