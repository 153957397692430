const calendarHelper = {
  days: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  today: 'Hoy',
  now: 'Ahora',
  am: 'AM',
  pm: 'PM'
}

const origenHelper = [
  { icon: 'envelope', caption: 'EMAIL', value: 1 },
  { icon: 'fax', caption: 'TELEFONO FIJO', value: 2 },
  { icon: 'mobile alternate', caption: 'TELEFONO CELULAR', value: 3 },
  { icon: 'whatsapp', caption: 'WHATSAPP', value: 4 },
  { icon: 'desktop', caption: 'PLATAFORMA', value: 5 },
  { icon: 'file alternate outline', caption: 'FISICO', value: 6 }
]

const entidadTipoIdentificacionHelper = [
  { caption: 'NIT', value: 1 },
  { caption: 'RUT', value: 2 }
]

const itemTicketTipo = [
  { caption: 'BUG', value: 1 },
  { caption: 'MEJORA', value: 2 },
  { caption: 'NUEVA FUNCION', value: 3 },
  { caption: 'ACTUALIZACION', value: 4 },
  { caption: 'SOPORTE', value: 5 },
  { caption: 'CAPACITACION', value: 6 },
  { caption: 'OTROS', value: 8 },
  { caption: 'VISITA TECNICA HOSPITALES', value: 9 }
]

export default {
  calendarHelper,
  origenHelper,
  entidadTipoIdentificacionHelper,
  itemTicketTipo
}
