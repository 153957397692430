<template>
  <div class="ui grid">
    <div class="four wide column">
      <div class="ui icon teal compact button" @click="applyFilter" data-content="Refrescar datos">
        <i class="redo icon"></i>
      </div>
      <div class="ui teal compact button" @click="downloadReport">
        <i class="file excel outline icon"></i>
        Exportar consulta actual
      </div>
    </div>
    <div class="twelve wide column" style="text-align: right;">
      <span class="span-text">Pag.</span>
      <div class="ui mini pagination menu">
        <a v-for="pag in pags" :key="pag.key" :class="`${(filterPag + 1) === pag.value ? 'active' : ''} item`" @click.stop="changePage(pag)">{{ pag.value }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tickets-table-controls',
  computed: {
    filterCant () {
      return this.$store.state.filterCant
    },
    filterPag () {
      return this.$store.state.filterPag
    }
  },
  data () {
    return {
      cantPaginas: 0,
      pags: []
    }
  },
  mounted () {
    this.changeCant(this.filterCant)
  },
  methods: {
    changePage (item) {
      this.$store.dispatch('filterPag', item.key)
      this.$root.$children[0].applyFilther()
    },
    downloadReport () {
      this.$root.$children[0].applyFilther('report', (rta) => {
        let binaryString = window.atob(rta.result.content)
        let len = binaryString.length
        let bytes = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }
        let file = new Blob([bytes.buffer], { type: rta.result.mimme })
        let fileURL = URL.createObjectURL(file)
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        a.href = fileURL
        a.download = 'reporte.xlsx'
        a.click()
        URL.revokeObjectURL(fileURL)
      })
    },
    applyFilter () {
      this.$root.$children[0].applyFilther()
    },
    changeCant (val) {
      this.cantPaginas = Math.trunc(val / 20) + ((val % 20) > 0 ? 1 : 0)
      this.pags = []
      let pagsVar = this.cantPaginas
      let positionPag = 1
      while (pagsVar > 0) {
        this.pags.push({
          key: positionPag - 1,
          value: positionPag
        })
        positionPag++
        pagsVar--
      }
    }
  },
  watch: {
    filterCant: function (val) {
      this.changeCant(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.span-text {
  margin-right: 10px;
}
</style>
