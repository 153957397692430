<template>
  <div class="ui middle aligned center aligned grid">
    <div class="ui segment ten wide column">
      <div class="ui active inverted dimmer" v-if="state === 'load'">
        <div class="ui active slow green double text loader">{{ loadText }}</div>
      </div>
      <div class="ui grid">
        <div class="eight wide column login-left">
          <h1 class="login-title">SoporteTecnico</h1>
          <span class="login-subtitle">Bienvenido</span>
          <p class="login-auxiliartitle">Iniciar sesión introduciendo su información a continuación.</p>
          <form class="ui large form">
            <div class="field">
              <div class="ui small left icon input">
                <i class="user icon"></i>
                <input type="text" name="email" placeholder="Ingrese su correo electronico o usuario" v-model="form.user" @keyup="keyEvent">
              </div>
            </div>
            <div class="field">
              <div class="ui small left icon input">
                <i class="lock icon"></i>
                <input type="password" name="password" placeholder="Ingrese su contraseña" v-model="form.pwd" @keyup="keyEvent">
              </div>
            </div>
            <div style="margin-top: 30px; margin-bottom: 20px;">
              <a class="ui small teal negative button" style="margin-top: 5px;" href="http://medisoftcolombia.com">Cancelar</a>
              <div class="ui small teal submit button" @click.stop="handleValidationForm">Iniciar sesion</div>
            </div>
          </form>
        </div>
        <div class="eight wide column login-right"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data () {
    return {
      state: 'idle',
      loadText: 'Cargando sus credenciales',
      form: {
        user: '',
        pwd: ''
      }
    }
  },
  methods: {
    handleValidationForm () {
      if (this.form.user === '' || this.form.pwd === '') {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: 'Debe suministrar tanto el usuario como la contraseña para poder continuar' })
      } else {
        this.state = 'load'
        this.loadText = 'Verificando sus credenciales, espere un momento...'
        this.$soportecore.api.source.userLogin(this.form.user, this.form.pwd).then((rta) => {
          this.$store.dispatch('filtersRestart')
          this.$store.dispatch('updateUser', rta.result[0])
          $('body').toast({ class: 'success', position: 'bottom left', displayTime: 10000, message: `Bienvenid@ ${rta.result[0].nombres} ${rta.result[0].apellidos}` })
          if (this.$route.query.redirect !== undefined) {
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            this.$router.push({ path: '/' })
          }
        }).catch((err) => {
          this.state = 'idle'
          if (err.code === 400) {
            $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: 'La combinacion de usuario y contraseña no son correctas. Intentelo nuevamente.' })
          } else {
            $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar crear verificar las credenciales` })
          }
        })
      }
    },
    keyEvent (e) {
      if (e.key === 'Enter') this.handleValidationForm()
    }
  }
}
</script>

<style lang="scss">
.login-title {
  font-family: 'Poppins', sans-serif;
  margin-top: 20px!important;
  margin-bottom: 120px!important;
  color: #2D6283;
}
.login-left {
  text-align: center!important;
  padding-left: 60px!important;
  padding-right: 60px!important;
}
.login-right {
  background-image: url('../assets/logininitial.jpg');
  background-size: cover;
  padding-top: 0px!important;
  padding-bottom: 0px!important;
  margin-top: -15px;
  margin-bottom: -15px;
}
.login-subtitle {
  font-weight: 800;
  font-size: 14px;
  opacity: 0.7;
}
.login-auxiliartitle {
  font-weight: 800;
  font-size: 12px;
  opacity: 0.4;
  margin-bottom: 20px!important;
}
</style>
