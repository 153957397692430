<template>
  <div class="ui raised segment">
    <div class="ui active inverted dimmer" v-if="state === 'load'">
      <div class="ui active slow green double text loader">{{ loadText }}</div>
    </div>
    <form class="ui form">
      <h4 class="ui dividing header">{{ `${type === 'new' ? 'Crear nuevo' : 'Editar '} Personal` }}</h4>
      <div class="field">
        <label>Nombres y apellidos</label>
        <input v-model="form.nombre" type="text" name="nombre" placeholder="Nombres y apellidos">
      </div>
      <div class="field">
        <label>Cargo</label>
        <input v-model="form.cargo" type="text" name="cargo" placeholder="Cargo">
      </div>
      <div v-if="showEntidadSelector" class="field">
        <label>Entidad</label>
        <list-selector ref="selector" element-select="entidad" :elements="entidades" :element-select-event="handleClickSelectorEntidad" :show-new-button="false">
          <template v-slot:editor>
            <entidad-new :reload-event="chargeEntidades"/>
          </template>
        </list-selector>
      </div>
      <div class="field" v-if="auditoriaShow && auditoriaData.length > 0">
        <label>Auditoria</label>
        <ListAuditoria :data="auditoriaData"/>
      </div>
      <div class="ui submit buttons">
        <div class="ui button" @click.stop="handleClose">Cancelar</div>
        <div class="or" data-text="o"></div>
        <div class="ui positive button" @click.stop="handleSave">Guardar</div>
      </div>
    </form>
  </div>
</template>

<script>
import ListSelector from './ListSelector'
import ListAuditoria from './ListAuditoria'
export default {
  name: 'entidad-new',
  props: {
    type: {
      type: String,
      default: 'new'
    },
    entidad: {
      type: Object,
      default: null
    },
    closeEvent: {
      type: Function,
      default: null
    },
    reloadEvent: {
      type: Function,
      default: null
    },
    showEntidadSelector: {
      type: Boolean,
      default: false
    },
    showAuditoria: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    profile () {
      return this.$store.state.user
    },
    dataEditor () {
      return this.$store.state.configDialogData
    }
  },
  components: {
    ListSelector,
    ListAuditoria
  },
  mounted () {
    this.chargeEntidades()
    if (this.dataEditor !== null || this.type !== 'new') {
      this.form = this.dataEditor
      this.$refs['selector'].setElement(this.dataEditor.entidad)
      this.chargeAuditoria(this.form.auto, 'STENTIPER')
      this.auditoriaShow = true
    } else {
      this.form = {
        auto: 0,
        nombre: '',
        cargo: ''
      }
      this.auditoriaShow = false
    }
  },
  data () {
    return {
      state: 'idle',
      loadText: 'Un momento',
      form: {
        auto: 0,
        nombre: '',
        cargo: ''
      },
      entidadSelected: null,
      entidades: [],
      auditoriaState: 'idle',
      auditoriaData: [],
      auditoriaShow: false
    }
  },
  methods: {
    handleClose () {
      if (this.closeEvent !== null) this.closeEvent()
      if (this.$parent.$options._componentTag === 'list-selector') this.$parent.handleCloseSlot()
    },
    handleSave () {
      this.state = 'load'
      this.loadText = this.dataEditor === null ? 'Creando personal...' : 'Actualizando personal...'
      if (this.dataEditor === null) {
        this.$soportecore.api.source.createPersonal(this.entidad !== null ? this.entidad.auto : this.entidadSelected.auto, {
          nombre: this.form.nombre,
          cargo: this.form.cargo,
          usuario: this.profile.auto
        }).then((rta) => {
          this.state = 'idle'
          this.form.auto = rta.result
          if (this.reloadEvent !== null) this.reloadEvent()
          if (this.$parent.$options._componentTag === 'list-selector') this.$parent.handleCloseSlot(this.form)
        }).catch((err) => {
          $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
          this.state = 'idle'
        })
      } else {
        this.$soportecore.api.source.updatePersonal({
          auto: this.dataEditor.auto,
          nombre: this.form.nombre,
          cargo: this.form.cargo,
          entidad: this.entidadSelected.auto,
          usuario: this.profile.auto
        }).then((rta) => {
          this.state = 'idle'
          this.form.auto = rta.result
          if (this.reloadEvent !== null) this.reloadEvent()
          if (this.$parent.$options._componentTag === 'list-selector') this.$parent.handleCloseSlot(this.form)
        }).catch((err) => {
          $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
          this.state = 'idle'
        })
      }
    },
    chargeEntidades () {
      this.loadText = 'Cargando entidades, un momento...'
      this.state = 'load'
      this.$soportecore.api.source.getEntidades().then((rta) => {
        this.entidades = rta.result
        this.state = 'idle'
      }).catch((err) => {
        console.log(err)
      })
    },
    handleClickSelectorEntidad (val) {
      this.entidadSelected = val
    },
    chargeAuditoria (auto, tabla) {
      this.auditoriaState = 'load'
      this.$soportecore.api.source.getAuditoria(auto, tabla).then((rta) => {
        this.auditoriaData = rta.result
        this.auditoriaState = 'idle'
      }).catch(() => {
        // $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
        this.auditoriaState = 'idle'
      })
    }
  }
}
</script>
