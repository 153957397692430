<template>
  <div class="ui segment">
    <div class="ui active inverted dimmer" v-if="state === 'load'">
      <div class="ui active slow green double text loader">{{ loadText }}</div>
    </div>
    <div class="ui form">
      <div class="field">
        <div :class="`ui ${renderState === 'advance' ? '' : 'mini'} action input`">
          <input ref="searchGeneral" type="text" placeholder="Escriba para buscar..." v-model="consecutivo" @focus="changeMode('input')" @keyup="keyEvent">
          <div :class="`ui ${renderState === 'advance' ? '' : 'mini'} ${stateIntern === 'idle' ? 'teal' : 'red'} icon button`" @click.stop="clickevent">
            <i :class="`${stateIntern === 'idle' ? 'search' : 'times'} icon`"></i>
          </div>
          <div :class="`ui ${renderState === 'advance' ? '' : 'mini'} blue icon button`" @click.stop="addEvent">
            Nuevo
            <!-- <i :class="`plus icon`"></i> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'filter-control',
  props: {
    datos: {
      type: Array,
      default: () => {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    state: {
      type: String,
      default: 'load'
    },
    searchEvent: {
      type: Function
    }
  },
  data () {
    return {
      renderState: 'basic',
      loadText: 'Un momento por favor...',
      consecutivo: '',
      originalData: [],
      stateIntern: 'idle'
    }
  },
  computed: {
    viewer () {
      return this.$store.state.configViewer
    },
    user () {
      return this.$store.state.user
    },
    filtros () {
      return this.$store.state.filters
    }
  },
  mounted () {
  },
  methods: {
    changeMode (origin = 'default') {
      if (this.renderState === 'basic') this.renderState = 'advance'
      else {
        if (origin === 'default') this.renderState = 'basic'
      }
    },
    keyEvent (e) {
      if (e.key === 'Enter') this.applyFilther()
    },
    clickevent () {
      if (this.stateIntern === 'search') {
        this.consecutivo = ''
        this.applyFilther()
      } else this.applyFilther()
    },
    addEvent () {
      this.$store.dispatch('configDialogDataViewer', this.viewer)
      $('.ui.basic.modal').modal('show')
    },
    applyFilther () {
      // this.$root.$children[0].applyFilther()
      // Filtro del objeto
      let newDatos = []
      this.originalData = this.datos
      if (this.consecutivo === '') {
        this.datos = this.originalData
        newDatos = this.originalData
        this.stateIntern = 'idle'
      } else {
        this.datos.forEach(dato => {
          this.columns.forEach(column => {
            console.log({
              buscado: this.consecutivo.toUpperCase(),
              valueoriginal: dato[column.propiedad].toString(),
              busqueda: dato[column.propiedad].toString().indexOf(this.consecutivo.toUpperCase())
            })
            if (dato[column.propiedad].toString().indexOf(this.consecutivo.toUpperCase()) > -1) {
              if (newDatos.indexOf(dato)) newDatos.push(dato)
            }
          })
        })
        this.stateIntern = 'search'
      }
      this.renderState = 'basic'
      this.$refs['searchGeneral'].blur()
      this.searchEvent(newDatos)
    }
  }
}
</script>

<style lang="css">
.border-label {
  border: 0px!important;
  opacity: 0.5;
  cursor: default;
}
</style>
