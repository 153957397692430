<template>
  <div class="ui raised segment tickets-new-container">
    <div class="ui active inverted dimmer" v-if="state === 'load'">
      <div class="ui active slow green double text loader">{{ loadText }}</div>
    </div>
    <div class="ui grid">
      <div class="four wide column">
        <div class="ui left floated animated button red" @click.stop="handleCloseForm">
          <div class="hidden content">Cerrar</div>
          <div class="visible content">
            <i class="times icon"></i>
          </div>
        </div>
      </div>
      <div class="eight wide column tickets-new-title">
        <h1>Crear Tickets</h1>
      </div>
      <div class="four wide column">
        <div class="ui right floated animated button teal" @click.stop="handleValidateForm">
          <div class="hidden content">Guardar</div>
          <div class="visible content">
            <i class="save icon"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="ui divider"></div>
    <form class="ui form tickets-new-form" :style="{ height: heightForm + 'px', maxHeight: heightForm + 'px', overflow: 'auto' }">
      <div class="field">
        <label>Consecutivo y Fecha de registro</label>
        <div class="fields">
          <div class="eight wide field">
            <input v-model="form.consecutivo" type="text" disabled name="consecutivo" placeholder="Consecutivo del Ticket">
          </div>
          <div class="four wide field">
            <div class="ui calendar" id="register_calendar">
              <div class="ui input left icon">
                <i class="calendar icon"></i>
                <input v-model="form.fechaShow" type="text" disabled placeholder="Fecha de registro">
              </div>
            </div>
          </div>
          <div class="four wide field">
            <div class="ui checkbox">
              <input type="checkbox" name="notify" v-model="form.notificar">
              <label>Notificar movimientos</label>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Fecha del Ticket | Origen</label>
        <div class="two fields">
          <div class="field">
            <div class="ui calendar" id="ticket_calendar">
              <div class="ui input left icon">
                <i class="calendar icon"></i>
                <input v-model="form.fechaTicketShow" type="text" readonly placeholder="Fecha del ticket">
              </div>
            </div>
          </div>
          <div class="field">
            <select class="ui dropdown" v-model="form.origen">
              <option v-for="origen in origenOptions" :key="origen.key" :value="origen.value">
                <i :class="`${origen.icon} icon`"></i>
                {{ origen.caption }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Entidad</label>
        <list-selector element-select="entidad" :elements="entidades" :element-select-event="handleClickSelectorEntidad">
          <template v-slot:editor>
            <entidad-new :reload-event="chargeEntidades"/>
          </template>
        </list-selector>
      </div>
      <div class="field">
        <label>Descripcion</label>
        <div class="ui left corner labeled input">
          <div class="ui left corner label">
              <i class="info icon"></i>
          </div>
          <textarea v-model="form.descripcion"></textarea>
        </div>
      </div>
      <div class="field" v-if="form.notificar">
        <label>Correos electronicos para notificar</label>
        <list-emails ref="listemailsTicket"/>
      </div>
      <div class="field">
        <label>Anexos</label>
        <list-anexos ref="listanexTicket" :editable="true" :show-add-button="true"/>
      </div>
      <div class="field" v-if="form.entidad !== null">
        <label>Items</label>
        <items-table ref="newTicketItems" type="create" :entidad="handleGetEntidad"/>
      </div>
    </form>
  </div>
</template>

<script>
import helper from '../libs/helpers.js'
import ListSelector from './ListSelector'
import ListAnexos from './ListAnexos'
import ListEmails from './ListEmails'
import ItemsTable from './ItemsTable'
import EntidadNew from './EntidadNew'
export default {
  name: 'tickets-new',
  props: {
    closeEvent: {
      type: Function,
      default: null
    }
  },
  components: {
    ListSelector,
    ListAnexos,
    ListEmails,
    ItemsTable,
    EntidadNew
  },
  computed: {
    profile () {
      return this.$store.state.user
    },
    origenOptions () {
      return helper.origenHelper
    },
    formEntidad () {
      return this.form.entidad
    },
    heightForm () {
      return this.$store.state.displayHeight - 100
    }
  },
  data () {
    return {
      state: 'idle',
      loadText: 'Un momento',
      form: {
        auto: 0,
        consecutivo: '',
        fecha: '',
        fechaShow: '',
        fechaTicket: '',
        fechaTicketShow: '',
        origen: 1,
        entidad: null,
        descripcion: '',
        notificar: true,
        items: [],
        anexos: [],
        emails: []
      },
      entidades: []
    }
  },
  mounted () {
    // Register DatePicker - Fecha de Registro
    $('#register_calendar').calendar({
      type: 'date',
      text: helper.calendarHelper,
      onChange: (value, text, choice) => {
        this.form.fecha = value
        this.form.fechaShow = text
      }
    })
    $('#register_calendar').calendar('set date', new Date(Date.now()))
    // Ticket DatePicker - Fecha del Ticket
    $('#ticket_calendar').calendar({
      type: 'date',
      text: helper.calendarHelper,
      onChange: (value, text, choice) => {
        this.form.fechaTicket = value
        this.form.fechaTicketShow = text
      }
    })
    $('#ticket_calendar').calendar('set date', new Date(Date.now()))
    // Carga de datos iniciales
    this.chargeEntidades()
  },
  methods: {
    chargeEntidades () {
      this.loadText = 'Cargando entidades, un momento...'
      this.state = 'load'
      this.$soportecore.api.source.getEntidades().then((rta) => {
        this.entidades = rta.result
        this.state = 'idle'
      }).catch((err) => {
        console.log(err)
      })
    },
    handleClickSelectorEntidad (val) {
      this.form.entidad = val
    },
    handleGetEntidad () {
      return this.form.entidad
    },
    handleValidateForm () {
      if (this.$refs['newTicketItems'] !== undefined) this.form.items = this.$refs['newTicketItems'].handleListItems()
      else this.form.items = []
      this.form.anexos = this.$refs['listanexTicket'].getElements()
      if (this.form.notificar === true) this.form.emails = this.$refs['listemailsTicket'].handleListItems()
      // Verificacion de los datos minimos para guardar un item de un ticket
      let errors = 0
      if (this.form.consecutivo === '') {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 9000, message: 'No se ha generado el consecutivo de este ticket, asegurese se haber seleccionado una Entidad.' })
        errors++
      }
      if (this.form.entidad === null) {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 9000, message: 'No ha seleccionado ninguna Entidad.' })
        errors++
      }
      if (this.form.descripcion === '') {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 9000, message: 'El campo opcional descripcion no se ha especificado, recuerde que aqui puede describir el contexto del ticket, no el del requerimiento.' })
        errors++
      }
      if (this.form.items.length === 0) {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 9000, message: 'No ha cargado ningun item a este Ticket.' })
        errors++
      }
      if (this.form.notificar === true && this.form.emails.length === 0) {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 9000, message: 'Ha definido como notificable este ticket y no ha cargado ningun email para notificar' })
        errors++
      }
      if (errors === 0) {
        this.handleSaveForm(this.handleCloseForm())
      }
    },
    handleSaveForm (__callback = null) {
      // Creacion del ticket principal
      // Actualizacion de pantalla de espera
      this.state = 'load'
      this.loadText = `Creando el Ticket ${this.form.consecutivo}... [Esta operacion puede tardar unos segundos por favor espere y no cierre el navegador]`
      // Ejecucion de creacion del ticket principal
      this.$soportecore.api.source.createTicket(this.profile.auto, {
        consecutivo: this.form.consecutivo,
        fecha: this.form.fecha,
        medio: this.form.origen,
        entidad: this.form.entidad.auto,
        descripcion: this.form.descripcion === '' ? ' ' : this.form.descripcion,
        notificar: this.form.notificar === true ? 1 : 0
      }).then((rta) => {
        this.form.auto = rta.result
        if (this.form.anexos.length > 0) {
          // Preparar nueva lista de anexos para crear
          let listAnexos = []
          this.form.anexos.forEach(anexo => {
            listAnexos.push({
              tipo: 1,
              key: this.form.auto,
              mimmeType: anexo.mimme,
              nombre: anexo.nombre,
              contenido: anexo.blob
            })
          })
          // Se crea un pool de promesas para los anexos del ticket y se espera a que todas finalicen
          let actionAnexos = listAnexos.map(this.$soportecore.api.source.createAnexo)
          let resultAnexos = Promise.all(actionAnexos)
          resultAnexos.then(respuestasAnexos => {
            if (respuestasAnexos.length !== this.form.anexos.length) {
              $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: 'Ha ocurrido un error al cargar los anexos del ticket contacte a soporte con este numero de ticket ' + this.form.consecutivo })
            }
          }).catch(err => {
            $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar crear el ticket: ${this.form.consecutivo}` })
          })
        }
        // Actualizacion de pantalla de espera
        if (this.form.notificar === true) {
          this.loadText = `Almacenando los correos de notificacion para el Ticket ${this.form.consecutive} ... [Esta operacion puede tardar unos segundos por favor espere y no cierre el navegador]`
          let listEmails = []
          this.form.emails.forEach(email => {
            listEmails.push({
              ticket: this.form.auto,
              nombre: email.nombre,
              email: email.email,
              modo: email.modo
            })
          })
          let actionEmails = listEmails.map((x) => { return this.$soportecore.api.source.createEmailInTickets(x) })
          let resultEmails = Promise.all(actionEmails)
          resultEmails.then(respuestaEmail => {
            if (respuestaEmail.length !== this.form.emails.length) {
              $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: 'Ha ocurrido un error al crear los emails contacte a soporte con este numero de ticket ' + this.form.consecutivo })
            } else {
              let descripcion = ''
              this.form.items.forEach(item => {
                if (descripcion !== '') descripcion += `|| ${item.descripcion}`
                else descripcion = item.descripcion
              })
              const notificationMail = {
                status: 'REGISTRADO',
                content: `${this.form.descripcion} || ${descripcion}`,
                ticket: this.form.consecutivo,
                name: this.form.emails.filter(x => x.modo === 0)[0].nombre,
                to: this.form.emails.map(x => {
                  return { mail: x.email, nombre: x.nombre, modo: x.modo }
                })
              }
              this.$soportecore.api.source.sendEmailNotify(notificationMail).then(() => {
                $('body').toast({ class: 'success', position: 'bottom left', displayTime: 10000, message: 'Se ha enviado una notificacion al cliente' })
              }).catch((err) => {
                if (err.code === undefined) {
                  $('body').toast({ class: 'success', position: 'bottom left', displayTime: 10000, message: 'Se ha enviado una notificacion al cliente' })
                } else {
                  $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar notificar al cliente` })
                }
              })
            }
          }).catch(err => {
            $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar crear el ticket: ${this.form.consecutivo} || emails` })
          })
        }

        // Actualizacion de pantalla de espera
        this.loadText = `Creando los items del Ticket ${this.form.consecutivo}... [Esta operacion puede tardar unos segundos por favor espere y no cierre el navegador]`
        // Preparar nueva lista de subitems para crear
        let listSubItems = []
        this.form.items.forEach(ticketItem => {
          listSubItems.push({
            ticket: this.form.auto,
            proyecto: ticketItem.proyecto.auto,
            modulo: ticketItem.modulo.auto,
            funcionario: ticketItem.personal.auto,
            tipo: ticketItem.tipo,
            descripcion: ticketItem.descripcion
          })
        })
        // Se crea un pool de promesas para los items y se espera a que todas finalicen
        let actionSubItems = listSubItems.map((x) => { return this.$soportecore.api.source.createTicketItem(this.profile.auto, x) })
        let resultSubItems = Promise.all(actionSubItems)
        resultSubItems.then(respuestasSubItems => {
          // Se asignan las nuevas llaves para los items creados siempre y cuando el numero de respuestas sea igual al numero de peticiones
          // de lo contrario se envia una notificacion al cliente que ocurrio un error y que debe contactar a soporte tecnico
          if (respuestasSubItems.length === this.form.items.length) {
            for (let i = 0; i < this.form.items.length; i++) {
              this.form.items[i].auto = respuestasSubItems[i].result
            }
            // Actualizacion de pantalla de espera
            this.loadText = `Creando los anexos de los items del Ticket ${this.form.consecutivo}... [Esta operacion puede tardar unos segundos por favor espere y no cierre el navegador]`
            // Para este caso se crea una bandera para saber cuando termina
            let cantEndAnexos = 0
            // finalmente se repite el proceso por cada item con sus respectivos anexos
            this.form.items.forEach(ticketItem => {
              // Preparar la lista de anexos para crear del item
              let listSubItemsAnexos = []
              ticketItem.anexos.forEach(anexo => {
                listSubItemsAnexos.push({
                  tipo: 2,
                  key: ticketItem.auto,
                  mimmeType: anexo.mimme,
                  nombre: anexo.nombre,
                  contenido: anexo.blob
                })
              })
              // Se crea un pool de promesas para los anexos del item y se espera a que todas finalicen
              let actionSubItemsAnexos = listSubItemsAnexos.map(this.$soportecore.api.source.createAnexo)
              let resultSubItemsAnexos = Promise.all(actionSubItemsAnexos)
              resultSubItemsAnexos.then(respuestasSubItemsAnexos => {
                cantEndAnexos++
                if (cantEndAnexos === this.form.items.length) {
                  this.state = 'idle'
                  $('body').toast({ class: 'success', position: 'bottom left', displayTime: 8000, message: 'Ticket creado bajo el radicado ' + this.form.consecutivo })
                  this.$root.$children[0].applyFilther()
                  if (__callback !== null) __callback()
                }
              }).catch((err) => {
                $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar crear el ticket: ${this.form.consecutivo}` })
                console.log(err)
              })
            })
          } else {
            $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: 'Ha ocurrido un error al crear el ticket contacte a soporte con este numero de ticket ' + this.form.consecutivo })
          }
        }).catch((err) => {
          console.log(err)
          $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar crear el ticket: ${this.form.consecutivo}` })
        })
      }).catch((err) => {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar crear el ticket: ${this.form.consecutivo}` })
      })
    },
    handleCloseForm () {
      this.$store.dispatch('updateDialogViewer', '')
    }
  },
  watch: {
    formEntidad: function (val) {
      if (val !== null) {
        let month = (this.form.fecha.getMonth() + 1) < 10 ? `0${this.form.fecha.getMonth() + 1}` : this.form.fecha.getMonth() + 1
        let day = this.form.fecha.getDate() < 10 ? `0${this.form.fecha.getDate()}` : this.form.fecha.getDate()
        let hours = this.form.fecha.getHours() < 10 ? `0${this.form.fecha.getHours()}` : this.form.fecha.getHours()
        let minutes = this.form.fecha.getMinutes() < 10 ? `0${this.form.fecha.getMinutes()}` : this.form.fecha.getMinutes()
        let seconds = this.form.fecha.getSeconds() < 10 ? `0${this.form.fecha.getSeconds()}` : this.form.fecha.getSeconds()
        this.form.consecutivo = `${this.form.fecha.getFullYear()}${month}${day}${hours}${minutes}${seconds}-${val.auto}`
      } else this.form.consecutivo = ''
    }
  }
}
</script>

<style lang="scss">
  .tickets-new-container {
    padding: 20px!important;
    height: 100%;
  }
  .tickets-new-title {
    text-align: center;
    opacity: 0.4;
  }
  .tickets-new-form {
    padding-left: 10px;
    padding-right: 10px;
  }
</style>
