<template>
  <div class="ui raised segment">
    <div class="ui active inverted dimmer" v-if="state === 'load'">
      <div class="ui active slow green double text loader">{{ loadText }}</div>
    </div>
    <form class="ui form">
      <h4 class="ui dividing header">{{ `${type === 'new' ? 'Crear nuevo' : 'Editar '} grupo` }}</h4>
      <div class="field">
        <label>Nombre del grupo</label>
        <input v-model="form.nombre" type="text" name="nombre" placeholder="Nombre del grupo">
      </div>
      <div class="field" v-if="type !== 'new'">
        <label>Procedimientos disponibles</label>
        <table class="ui small selectable celled padded single line table compact" style="max-height: 50px!important;">
          <tbody>
            <tr v-for="item in procedimientos" :key="item.auto" style="cursor: pointer;" @click="handleClick(item)" :class="item.auto === procedimiento.auto ? 'active' : ''">
              <td data-label="Auto">{{ item.auto }}</td>
              <td data-label="Nombre">{{ item.nombreProceso }} - {{ item.nombreProcedimiento }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="ui field basic segment" v-if="procedimiento.auto !== 0">
        <label>Permisos definidos</label>
        <div class="ui active inverted dimmer" v-if="stateProcedimiento === 'load'">
          <div class="ui active slow green double text loader">Cargando</div>
        </div>
        <div v-if="procedimiento.permisos !== undefined">
          <p-check class="p-default p-curve p-pulse p-smooth" style="color: black;" color="success" v-model="procedimiento.permisos.permisoNuevo" @change="handleCheckChange">Nuevo</p-check>
          <p-check class="p-default p-curve p-pulse p-smooth" style="color: black;" color="success" v-model="procedimiento.permisos.permisoEdicion" @change="handleCheckChange">Edicion</p-check>
          <p-check class="p-default p-curve p-pulse p-smooth" style="color: black;" color="success" v-model="procedimiento.permisos.permisoConsulta" @change="handleCheckChange">Consultar</p-check>
          <p-check class="p-default p-curve p-pulse p-smooth" style="color: black;" color="success" v-model="procedimiento.permisos.permisoAnular" @change="handleCheckChange">Anular</p-check>
          <p-check class="p-default p-curve p-pulse p-smooth" style="color: black;" color="success" v-model="procedimiento.permisos.permisoEnviar" @change="handleCheckChange">Enviar</p-check>
          <p-check class="p-default p-curve p-pulse p-smooth" style="color: black;" color="success" v-model="procedimiento.permisos.permisoAdmin" @change="handleCheckChange">Administrador</p-check>
          <button v-show="changeForm" class="mini ui teal button checkbox-options" @click.stop="handleSavePermisos">
            Actualizar permisos
          </button>
        </div>
      </div>
      <div class="field" v-if="auditoriaShow && auditoriaData.length > 0">
        <label>Auditoria</label>
        <ListAuditoria :data="auditoriaData"/>
      </div>
      <div class="ui submit buttons">
        <div class="ui button" @click.stop="handleClose">Cancelar</div>
        <div class="or" data-text="o"></div>
        <div class="ui positive button" @click.stop="handleSave">Guardar</div>
      </div>
    </form>
  </div>
</template>

<script>
import ListAuditoria from './ListAuditoria'
export default {
  name: 'grupos-new',
  components: {
    ListAuditoria
  },
  props: {
    type: {
      type: String,
      default: 'new'
    },
    closeEvent: {
      type: Function,
      default: null
    },
    reloadEvent: {
      type: Function,
      default: null
    },
    showAuditoria: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    profile () {
      return this.$store.state.user
    },
    dataEditor () {
      return this.$store.state.configDialogData
    }
  },
  data () {
    return {
      state: 'idle',
      loadText: 'Un momento',
      form: {
        auto: 0,
        nombre: ''
      },
      stateProcedimiento: 'idle',
      procedimientos: [],
      procedimiento: {
        auto: 0
      },
      changeForm: false,
      auditoriaState: 'idle',
      auditoriaData: [],
      auditoriaShow: false
    }
  },
  mounted () {
    this.chargeProcedimientos()
    if (this.dataEditor !== null || this.type !== 'new') {
      this.form = this.dataEditor
      this.chargeAuditoria(this.form.auto, 'STGRUPOS')
      this.auditoriaShow = true
    } else {
      this.form = {
        auto: 0,
        nombre: ''
      }
      this.auditoriaShow = false
    }
  },
  methods: {
    handleClick (item) {
      this.procedimiento = item
      this.chargePermisos(this.dataEditor.auto, this.procedimiento.auto)
    },
    handleCheckChange () {
      this.changeForm = true
    },
    handleClose () {
      if (this.closeEvent !== null) this.closeEvent()
      if (this.$parent.$options._componentTag === 'list-selector') this.$parent.handleCloseSlot()
    },
    handleSave () {
      this.state = 'load'
      this.loadText = this.dataEditor === null ? 'Creando grupo...' : 'Actualizando grupo...'
      if (this.dataEditor === null) {
        this.$soportecore.api.source.createGrupo({
          nombre: this.form.nombre,
          usuario: this.profile.auto
        }).then((rta) => {
          this.state = 'idle'
          this.form.auto = rta.result
          if (this.reloadEvent !== null) this.reloadEvent()
          if (this.$parent.$options._componentTag === 'list-selector') this.$parent.handleCloseSlot(this.form)
        }).catch((err) => {
          $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
          this.state = 'idle'
        })
      } else {
        this.$soportecore.api.source.updateGrupo({
          auto: this.dataEditor.auto,
          nombre: this.form.nombre,
          usuario: this.profile.auto
        }).then((rta) => {
          this.state = 'idle'
          this.form.auto = rta.result
          if (this.reloadEvent !== null) this.reloadEvent()
          if (this.$parent.$options._componentTag === 'list-selector') this.$parent.handleCloseSlot(this.form)
        }).catch((err) => {
          $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
          this.state = 'idle'
        })
      }
    },
    handleSavePermisos () {
      this.state = 'load'
      this.loadText = 'Escribiendo en los permisos'
      if (this.procedimiento.permisos.auto === 0) {
        this.$soportecore.api.source.createPermiso({
          autoProcedimiento: this.procedimiento.auto,
          autoGrupo: this.dataEditor.auto,
          permisoNuevo: this.procedimiento.permisos.permisoNuevo === true ? 1 : 0,
          permisoEdicion: this.procedimiento.permisos.permisoEdicion === true ? 1 : 0,
          permisoConsulta: this.procedimiento.permisos.permisoConsulta === true ? 1 : 0,
          permisoAnular: this.procedimiento.permisos.permisoAnular === true ? 1 : 0,
          permisoEnviar: this.procedimiento.permisos.permisoEnviar === true ? 1 : 0,
          permisoAdmin: this.procedimiento.permisos.permisoAdmin === true ? 1 : 0,
          usuario: this.profile.auto
        }).then((rta) => {
          this.state = 'idle'
          this.changeForm = false
        }).catch((err) => {
          $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
          this.state = 'idle'
        })
      } else {
        this.$soportecore.api.source.updatePermiso({
          auto: this.procedimiento.permisos.auto,
          autoProcedimiento: this.procedimiento.auto,
          autoGrupo: this.dataEditor.auto,
          permisoNuevo: this.procedimiento.permisos.permisoNuevo === true ? 1 : 0,
          permisoEdicion: this.procedimiento.permisos.permisoEdicion === true ? 1 : 0,
          permisoConsulta: this.procedimiento.permisos.permisoConsulta === true ? 1 : 0,
          permisoAnular: this.procedimiento.permisos.permisoAnular === true ? 1 : 0,
          permisoEnviar: this.procedimiento.permisos.permisoEnviar === true ? 1 : 0,
          permisoAdmin: this.procedimiento.permisos.permisoAdmin === true ? 1 : 0,
          usuario: this.profile.auto
        }).then((rta) => {
          this.state = 'idle'
          this.changeForm = false
        }).catch((err) => {
          $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
          this.state = 'idle'
        })
      }
    },
    chargeAuditoria (auto, tabla) {
      this.auditoriaState = 'load'
      this.$soportecore.api.source.getAuditoria(auto, tabla).then((rta) => {
        this.auditoriaData = rta.result
        this.auditoriaState = 'idle'
      }).catch(() => {
        // $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
        this.auditoriaState = 'idle'
      })
    },
    chargeProcedimientos () {
      this.state = 'load'
      this.$soportecore.api.source.getProcedimientos().then((rta) => {
        this.procedimientos = rta.result
        this.state = 'idle'
      }).catch(() => {
        this.state = 'idle'
      })
    },
    chargePermisos (grupo, procedimiento) {
      this.stateProcedimiento = 'load'
      this.$soportecore.api.source.getPermisos(grupo, procedimiento).then((rta) => {
        this.procedimiento.permisos = rta.result[0]
        this.stateProcedimiento = 'idle'
      }).catch((err) => {
        if (err.code === 400) {
          this.procedimiento.permisos = {
            auto: 0,
            autoGrupo: grupo,
            autoProcedimiento: procedimiento,
            permisoAdmin: false,
            permisoAnular: false,
            permisoConsulta: false,
            permisoEdicion: false,
            permisoEnviar: false,
            permisoNuevo: false
          }
        }
        this.stateProcedimiento = 'idle'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-options {
  margin-left: 20px;
}
</style>
