<template>
  <div class="ui raised segment">
    <div class="ui active inverted dimmer" v-if="state === 'load'">
      <div class="ui active slow green double text loader">{{ loadText }}</div>
    </div>
    <form class="ui form">
      <h4 class="ui dividing header">{{ `${type === 'new' ? 'Crear nueva' : 'Editar '} entidad` }}</h4>
      <div class="field">
        <label>Numero Identificacion y Tipo Identificacion</label>
        <div class="fields">
          <div class="twelve wide field">
            <input v-model="form.identificacion" type="text" name="identificacion" placeholder="Numero identificacion">
          </div>
          <div class="four wide field">
            <select class="ui dropdown" v-model="form.tipoIdentificacion" placeholder="Tipo de identificacion">
              <option v-for="tipo in tiposIdentificacionOptions" :key="tipo.key" :value="tipo.value">
                {{ tipo.caption }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Nombre de la entidad</label>
        <input v-model="form.nombre" type="text" name="nombre" placeholder="Nombre de la entidad">
      </div>
      <div class="field" v-if="auditoriaShow && auditoriaData.length > 0">
        <label>Auditoria</label>
        <ListAuditoria :data="auditoriaData"/>
      </div>
      <div class="ui submit buttons">
        <div class="ui button" @click.stop="handleClose">Cancelar</div>
        <div class="or" data-text="o"></div>
        <div class="ui positive button" @click.stop="handleSave">Guardar</div>
      </div>
    </form>
  </div>
</template>

<script>
import helper from '../libs/helpers.js'
import ListAuditoria from './ListAuditoria'
export default {
  name: 'entidad-new',
  components: {
    ListAuditoria
  },
  props: {
    type: {
      type: String,
      default: 'new'
    },
    closeEvent: {
      type: Function,
      default: null
    },
    reloadEvent: {
      type: Function,
      default: null
    },
    showAuditoria: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tiposIdentificacionOptions () {
      return helper.entidadTipoIdentificacionHelper
    },
    profile () {
      return this.$store.state.user
    },
    dataEditor () {
      return this.$store.state.configDialogData
    }
  },
  data () {
    return {
      state: 'idle',
      loadText: 'Un momento',
      form: {
        auto: 0,
        identificacion: '',
        tipoIdentificacion: '',
        nombre: '',
        estado: 1
      },
      tiposIdentificacion: [],
      auditoriaState: 'idle',
      auditoriaData: [],
      auditoriaShow: false
    }
  },
  mounted () {
    if (this.dataEditor !== null || this.type !== 'new') {
      this.form = this.dataEditor
      this.chargeAuditoria(this.form.auto, 'STENTIDAD')
      this.auditoriaShow = true
    } else {
      this.form = {
        auto: 0,
        identificacion: '',
        tipoIdentificacion: '',
        nombre: '',
        estado: 1
      }
      this.auditoriaShow = false
    }
  },
  methods: {
    handleClose () {
      if (this.closeEvent !== null) this.closeEvent()
      if (this.$parent.$options._componentTag === 'list-selector') this.$parent.handleCloseSlot()
    },
    handleSave () {
      this.state = 'load'
      this.loadText = this.dataEditor === null ? 'Creando entidad...' : 'Actualizando entidad...'
      if (this.dataEditor === null) {
        this.$soportecore.api.source.createEntidad({
          identificacion: this.form.identificacion,
          tipoIdentificacion: this.form.tipoIdentificacion,
          nombre: this.form.nombre,
          usuario: this.profile.auto
        }).then((rta) => {
          this.state = 'idle'
          this.form.auto = rta.result
          if (this.reloadEvent !== null) this.reloadEvent()
          if (this.$parent.$options._componentTag === 'list-selector') this.$parent.handleCloseSlot(this.form)
        }).catch((err) => {
          $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
          this.state = 'idle'
        })
      } else {
        this.$soportecore.api.source.updateEntidad({
          auto: this.dataEditor.auto,
          identificacion: this.form.identificacion,
          tipoIdentificacion: this.form.tipoIdentificacion,
          nombre: this.form.nombre,
          usuario: this.profile.auto
        }).then((rta) => {
          this.state = 'idle'
          this.form.auto = rta.result
          if (this.reloadEvent !== null) this.reloadEvent()
          if (this.$parent.$options._componentTag === 'list-selector') this.$parent.handleCloseSlot(this.form)
        }).catch((err) => {
          $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
          this.state = 'idle'
        })
      }
    },
    chargeAuditoria (auto, tabla) {
      this.auditoriaState = 'load'
      this.$soportecore.api.source.getAuditoria(auto, tabla).then((rta) => {
        this.auditoriaData = rta.result
        this.auditoriaState = 'idle'
      }).catch(() => {
        // $('body').toast({ class: 'error', position: 'bottom left', message: err.help })
        this.auditoriaState = 'idle'
      })
    }
  }
}
</script>
