<template>
  <div>
    <div v-if="type === 'mini'">
      <div class="ui image label">
        <img :src="`data:${'image/png'};base64,${profile.imagen}`">
        {{ `${profile.iniciales}` }}
      </div>
    </div>
    <div class="ui card" v-if="type === 'normal'">
      <div class="content" style="padding: 5px;">
        <img class="ui avatar image image-profile" :src="`data:${'image/png'};base64,${profile.imagen}`"> {{ `${profile.nombres} ${profile.apellidos}` }}
      </div>
      <div class="ui mini bottom attached button" @click="handleClickLogOut">
        <i class="sign out alternate icon"></i>
        {{ `Salir de ${profile.usd}` }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PerfilCard',
  props: {
    type: {
      type: String,
      default: 'normal'
    }
  },
  computed: {
    profile () {
      return this.$store.state.user
    }
  },
  methods: {
    handleClickLogOut () {
      this.$store.dispatch('updateUser', null)
      this.$router.push({ path: '/login' })
    }
  }
}
</script>

<style scoped>
  .image-profile {
    /* height: 150px!important; */
    object-fit:contain!important;
  }
</style>
