export default {
  entidades: '/api/entidades',
  proyectos: '/api/proyectos',
  modulos: '/api/modulos',
  personal: '/api/personal',
  tickets: '/api/tickets',
  ticketsNotificar: '/api/tickets/notify',
  ticketsPaginacion: '/api/tickets/paginacion/list',
  ticketsItems: '/api/tickets/items',
  ticketsTrazas: '/api/tickets/trazas',
  ticketsEmails: '/api/tickets/emails',
  anexos: '/api/anexos',
  authUser: '/api/auth/user',
  usuarios: '/api/usuarios',
  reportes: '/api/reportes',
  grupos: '/api/usuarios/grupos',
  auditoria: '/api/auditoria',
  configProcedimientos: '/api/usuarios/procedimientos',
  configPermisos: '/api/usuarios/permisos',
  configRelaciones: '/api/usuarios/relaciongrupo'
}
