<template>
  <div :class="`tickets-table-container ui ${tickets.length < 1 ? 'placeholder' : ''} segment`">
    <div class="ui active inverted dimmer" v-if="state === 'load'">
      <div class="ui active slow green double text loader">{{ chargeMessage }}</div>
    </div>
    <div v-if="tickets.length < 1" class="ui icon header">
      <i class="archive icon"></i>
      No hay tickets para mostrarle...
    </div>
    <table v-if="tickets.length > 0" class="ui small selectable celled padded single line table compact tickets-table-container">
      <tbody>
        <tr v-for="item in tickets" :key="item.item.auto" style="cursor: pointer;" :class="actualTicket.item.auto === item.item.auto ? 'active' : (item.traza.auto === 5 ? 'red' : item.traza.auto === 4 ? 'green' : item.traza.auto === 3 ? 'orange' : item.traza.auto === 2 ? 'yellow' : 'teal')"
          @dblclick="handledblClickRow(item)">
          <td data-label="Consecutivo" class="two wide">
            <i :class="`${actualTicket.item.auto === item.item.auto ? 'chevron circle right' : (item.traza.auto === 5 ? 'ban' : item.traza.auto === 4 ? 'check circle' : item.traza.auto === 3 ? 'clock circle' : item.traza.auto === 2 ? 'info circle' : 'registered')} icon`"
              :title="item.traza.nombre"></i>
            {{ item.consecutivo }}
          </td>
          <td data-label="Usuario" class="one wide">
            <i :class="`${item.medio === '1' ? 'envelope' : item.medio === '2' ? 'fax' : item.medio === '3' ? 'mobile alternate' : item.medio === '4' ? 'whatsapp' : item.medio === '5' ? 'desktop' : 'file alternate outline'} icon`"
              :title="item.medio === '1' ? 'EMAIL' : item.medio === '2' ? 'FIJO' : item.medio === '3' ? 'MOVIL' : item.medio === '4' ? 'WHATSAPP' : item.medio === '5' ? 'PLATAFORMA' : 'DOCUMENTO'"></i>
            <i :class="`${item.tipo === '1' ? 'bug' : item.tipo === '2' ? 'file upload' : item.tipo === '3' ? 'external link square alternate' : item.tipo === '4' ? 'upload' : item.tipo === '5' ? 'user astronaut' : item.tipo === '6' ? 'book' : 'podcast'} icon`"
              :title="item.tipo === '1' ? 'BUG' : item.tipo === '2' ? 'MEJORA' : item.tipo === '3' ? 'NUEVA FUNCION' : item.tipo === '4' ? 'ACTUALIZACION' : item.tipo === '5' ? 'SOPORTE' : item.tipo === '6' ? 'CAPACITACION' : 'OTROS'"></i>
            <span :title="item.usuario.nombres + ' ' + item.usuario.apellidos">{{ item.usuario.iniciales }}</span>
          </td>
          <td data-label="Solicitante">{{ item.entidad.nombre }}</td>
          <td data-label="Personal">{{ `${item.funcionario.nombre}` }}</td>
          <td data-label="Plataforma" class="three wide">{{ `${item.proyecto.nombre} [${item.modulo.nombre}]` }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'tickets-table',
  computed: {
    state () {
      return this.$store.state.filterState
    },
    tickets () {
      return this.$store.state.filterResult
    },
    actualTicket () {
      return this.$store.state.ticketEditor
    },
    chargeMessage () {
      return this.$store.state.filterStateMessage
    }
  },
  methods: {
    handledblClickRow (item) {
      this.$store.dispatch('ticketEditorUpdate', item)
      this.$store.dispatch('updateDialogViewer', 'tickets-editor')
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/soporte-variables.scss';
  .tickets-table-container {
    max-height: 100%;
    overflow: auto;
    padding: 0px!important;
  }
  .label-no-borderer {
    border: none!important;
    &.left {
      float: left;
    }
    &.right {
      float: right;
    }
  }
</style>
