<template>
  <div :class="`tickets-table-container ui ${tickets.length < 1 ? 'placeholder' : ''} segment`">
    <div class="ui active inverted dimmer" v-if="state === 'load'">
      <div class="ui active slow green double text loader">{{ 'Cargando informacion...' }}</div>
    </div>
    <div v-if="tickets.length < 1" class="ui icon header">
      <i class="archive icon"></i>
      No hay tickets para mostrarle...
    </div>
    <table v-if="tickets.length > 0" class="ui small selectable celled padded single line table compact tickets-table-container">
      <!-- <thead>
        <tr>
          <th v-for="column in columns" :key="column.key">{{ column.titulo }}</th>
        </tr>
      </thead> -->
      <tbody>
        <tr v-for="item in datos" :key="item.auto" style="cursor: pointer;" @dblclick="handledblClickRow(item)">
          <td v-for="column in columns" :key="column.key" :data-label="column.titulo" :class="column.class">{{ item[column.propiedad] }}</td>
        </tr>
      </tbody>
    </table>
    <div class="ui basic modal" style="height: auto!important;">
      <div class="content">
        <UsuarioNew  v-if="dataViewer === 'usuarios'" :close-event="handleClosedialog" :reload-event="handleClosedialog" :type="dataEditor === null ? 'new' : 'edit'"/>
        <GruposNew  v-if="dataViewer === 'grupos'" :close-event="handleClosedialog" :reload-event="handleClosedialog" :type="dataEditor === null ? 'new' : 'edit'"/>
        <EntidadNew v-if="dataViewer === 'entidades'" :close-event="handleClosedialog" :reload-event="handleClosedialog" :type="dataEditor === null ? 'new' : 'edit'"/>
        <PersonalNew v-if="dataViewer === 'funcionarios'" :close-event="handleClosedialog" :reload-event="handleClosedialog" :type="dataEditor === null ? 'new' : 'edit'" :show-entidad-selector="true"/>
        <ProyectoNew v-if="dataViewer === 'proyectos'" :close-event="handleClosedialog" :reload-event="handleClosedialog" :type="dataEditor === null ? 'new' : 'edit'"/>
        <ModuloNew v-if="dataViewer === 'modulos'" :close-event="handleClosedialog" :reload-event="handleClosedialog" :type="dataEditor === null ? 'new' : 'edit'" :show-proyecto-selector="true"/>
      </div>
    </div>
  </div>
</template>

<script>
import UsuarioNew from '@/components/UsuarioNew.vue'
import GruposNew from '@/components/GruposNew.vue'
import EntidadNew from '@/components/EntidadNew.vue'
import PersonalNew from '@/components/PersonalNew.vue'
import ProyectoNew from '@/components/ProyectoNew.vue'
import ModuloNew from '@/components/ModuloNew.vue'
export default {
  name: 'tickets-table',
  props: {
    datos: {
      type: Array,
      default: () => {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    state: {
      type: String,
      default: 'load'
    },
    reloadEvent: {
      type: Function
    }
  },
  computed: {
    viewer () {
      return this.$store.state.configViewer
    },
    tickets () {
      return this.$store.state.filterResult
    },
    actualTicket () {
      return this.$store.state.ticketEditor
    },
    chargeMessage () {
      return this.$store.state.filterStateMessage
    },
    dataEditor () {
      return this.$store.state.configDialogData
    },
    dataViewer () {
      return this.$store.state.configDialogViewer
    }
  },
  components: {
    EntidadNew,
    PersonalNew,
    ProyectoNew,
    ModuloNew,
    GruposNew,
    UsuarioNew
  },
  data () {
    return {
      dialog: 'entidad'
    }
  },
  methods: {
    handledblClickRow (item) {
      this.$store.dispatch('configDialogDataUpdate', item)
      this.$store.dispatch('configDialogDataViewer', this.viewer)
      $('.ui.basic.modal').modal('show')
    },
    handleClosedialog () {
      this.$store.dispatch('configDialogDataUpdate', null)
      this.$store.dispatch('configDialogDataViewer', null)
      $('.ui.basic.modal').modal('hide')
      this.reloadEvent()
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/soporte-variables.scss';
  .tickets-table-container {
    max-height: 100%;
    overflow: auto;
    padding: 0px!important;
  }
  .label-no-borderer {
    border: none!important;
    &.left {
      float: left;
    }
    &.right {
      float: right;
    }
  }
</style>
