import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Config from '../views/Configuracion.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/config',
    name: 'config',
    component: Config,
    meta: {
      requireAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const actualUser = Store.state.user
  const requiresAuth = to.matched.some(record => record.meta.requireAuth)
  console.log('to', to)
  console.log('from', from)
  if (requiresAuth === true && actualUser === null) {
    let query = {}
    if (Object.keys(to.query).length > 0) query.redirect = to.fullPath
    next({
      path: '/login',
      query: query
    })
    $('body').toast({ class: 'warning', position: 'bottom left', displayTime: 10000, message: 'Necesita tener una sesion iniciada antes de ver la pagina que esta solicitando, por favor inicie sesion.' })
  } else {
    next()
  }
})

export default router
