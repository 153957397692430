<template>
  <div class="ui grid relaxed">
    <!-- Menu de navegacion lateral para version de escritorio -->
    <div class="three wide column" style="padding-top: 20px; padding-left: 30px; padding-right: 5px;">
      <filter-menu details="configuration"/>
    </div>
    <!-- Tabla y filtros -->
    <div class="thirteen wide column" style="padding-top: 20px; padding-left: 5px; padding-right: 30px;">
      <general-filter :datos="itemsFilter" :columns="columns" :state="state" :search-event="searchEvent"/>
      <general-table :style="{ height: heightRow + 'px', maxHeight: heightRow + 'px' }" :datos="items" :columns="columns" :state="state" :reload-event="chargeData"/>
    </div>
  </div>
</template>

<script>
import GeneralFilter from '@/components/GeneralFilter.vue'
import GeneralTable from '@/components/GeneralTable.vue'
import FilterMenu from '@/components/FilterMenu.vue'
export default {
  name: 'Configuracion',
  data () {
    return {
      items: [],
      itemsFilter: [],
      columns: [],
      state: 'load'
    }
  },
  components: {
    FilterMenu,
    GeneralFilter,
    GeneralTable
  },
  computed: {
    viewer () {
      return this.$store.state.configViewer
    },
    heightRow () {
      return this.$store.state.displayHeight - 100
    }
  },
  mounted () {
    this.$store.dispatch('configViewerUpdate', 'grupos')
    this.chargeData()
  },
  methods: {
    chargeData () {
      this.state = 'load'
      // Obtencion de informacion de los grupos
      if (this.viewer === 'grupos') {
        this.$soportecore.api.source.getGrupos().then((rta) => {
          // Carga de informacion
          this.itemsFilter = rta.result
          this.items = rta.result
          this.state = 'normal'
          // Definicion de las columnas a utilizar
          this.columns = [
            {
              titulo: 'Auto',
              class: 'two wide',
              propiedad: 'auto'
            },
            {
              titulo: 'Nombre',
              class: '',
              propiedad: 'nombre'
            }
          ]
        })
      } else if (this.viewer === 'usuarios') {
        this.$soportecore.api.source.getUsuarios().then((rta) => {
          // Carga de informacion
          this.itemsFilter = rta.result
          this.items = rta.result
          this.state = 'normal'
          // Definicion de las columnas a utilizar
          this.columns = [
            {
              titulo: 'Auto',
              class: 'two wide',
              propiedad: 'auto'
            },
            {
              titulo: 'Identificacion',
              class: '',
              propiedad: 'identificacion'
            },
            {
              titulo: 'Iniciales',
              class: 'two wide',
              propiedad: 'iniciales'
            },
            {
              titulo: 'Nombres y Apellidos',
              class: '',
              propiedad: 'nombresApellidos'
            }
          ]
        })
      } else if (this.viewer === 'entidades') {
        this.$soportecore.api.source.getEntidades().then((rta) => {
          // Carga de informacion
          this.itemsFilter = rta.result
          this.items = rta.result
          this.state = 'normal'
          // Definicion de las columnas a utilizar
          this.columns = [
            {
              titulo: 'Auto',
              class: 'two wide',
              propiedad: 'auto'
            },
            {
              titulo: 'Identificacion',
              class: '',
              propiedad: 'identificacion'
            },
            {
              titulo: 'Nombre',
              class: '',
              propiedad: 'nombre'
            }
          ]
        })
      } else if (this.viewer === 'funcionarios') {
        this.$soportecore.api.source.getPersonal(0).then((rta) => {
          // Carga de informacion
          this.itemsFilter = rta.result
          this.items = rta.result
          this.state = 'normal'
          // Definicion de las columnas a utilizar
          this.columns = [
            {
              titulo: 'Auto',
              class: 'two wide',
              propiedad: 'auto'
            },
            {
              titulo: 'Nombre',
              class: '',
              propiedad: 'nombre'
            },
            {
              titulo: 'Cargo',
              class: '',
              propiedad: 'cargo'
            },
            {
              titulo: 'Entidad',
              class: '',
              propiedad: 'entidadNombre'
            }
          ]
        })
      } else if (this.viewer === 'proyectos') {
        this.$soportecore.api.source.getProyectos().then((rta) => {
          // Carga de informacion
          this.itemsFilter = rta.result
          this.items = rta.result
          this.state = 'normal'
          // Definicion de las columnas a utilizar
          this.columns = [
            {
              titulo: 'Auto',
              class: 'two wide',
              propiedad: 'auto'
            },
            {
              titulo: 'Nombre',
              class: '',
              propiedad: 'nombre'
            }
          ]
        })
      } else if (this.viewer === 'modulos') {
        this.$soportecore.api.source.getModulos(0).then((rta) => {
          // Carga de informacion
          this.itemsFilter = rta.result
          this.items = rta.result
          this.state = 'normal'
          // Definicion de las columnas a utilizar
          this.columns = [
            {
              titulo: 'Auto',
              class: 'two wide',
              propiedad: 'auto'
            },
            {
              titulo: 'Nombre',
              class: '',
              propiedad: 'nombre'
            },
            {
              titulo: 'Proyecto',
              class: '',
              propiedad: 'proyectoNombre'
            }
          ]
        })
      }
    },
    searchEvent (value) {
      this.items = value
    }
  },
  watch: {
    viewer: function (val) {
      this.chargeData()
    }
  }
}
</script>
