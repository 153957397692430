<template>
  <div class="items-table-container">
    <email-new v-if="state === 'new' || state === 'edit'" :type="state" :closeEvent="closeNewDialog" :saveEvent="saveNewDialog" :value="value2Edit"/>
    <table v-if="state === 'idle' && data.length > 0" class="ui celled small padded single line table items-table-container">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Email</th>
          <th class="two wide">Modo</th>
          <th class="two wide">
            <div :class="`mini ui ${editorLoad === 'load' ? 'loading' : ''} primary button`" @click="handleNewElement">
              AGREGAR
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.email">
          <td data-label="Nombre">
            {{ item.nombre }}
          </td>
          <td data-label="Email">{{ item.email }}</td>
          <td data-label="Modo">{{ item.modo }}</td>
          <td data-label="">
            <div class="ui small basic icon buttons">
              <div class="ui button" @click="handleEditElement(item)"><i class="edit icon"></i></div>
              <div class="ui button" @click="handleDeleteElement(item)"><i class="trash alternate icon"></i></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="state === 'idle' && data.length < 1" class="ui placeholder segment">
      <div class="ui icon header">
        <i class="exclamation triangle icon"></i>
        No existen emails para notificar en este ticket, recuerde que si marco la opcion de notificar actuaciones debe agregar al menos un email
      </div>
      <div class="ui teal labeled icon button" @click="handleNewElement">
        Añadir email de notificacion al ticket
        <i class="add icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
import EmailNew from './EmailNew.vue'
export default {
  name: 'ListEmails',
  components: {
    EmailNew
  },
  data () {
    return {
      state: 'idle',
      data: [],
      editorLoad: 'idle',
      value2Edit: null
    }
  },
  methods: {
    openNewDialog () {
      this.state = 'new'
      this.editorLoad = 'idle'
    },
    closeNewDialog () {
      this.state = 'idle'
    },
    saveNewDialog (data) {
      if (!data.auto) {
        data.auto = this.data.length + 1
        this.data.push(data)
      }
      this.state = 'idle'
    },
    handleDeleteElement (item) {
      this.data = this.data.filter(element => element.auto !== item.auto)
    },
    handleEditElement (item) {
      this.value2Edit = item
      this.state = 'edit'
      this.openNewDialog()
    },
    handleNewElement (item) {
      this.value2Edit = null
      this.openNewDialog()
    },
    handleListItems () {
      return this.data
    }
  }
}
</script>
