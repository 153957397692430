<template>
  <div id="app" :class="actualPath === '/login' ? 'login-background' : 'default-background'">
    <!-- Menu para la version movil del lado derecho -->
    <div class="ui left sidebar inverted vertical menu">
      <a class="item">
        1
      </a>
      <a class="item">
        2
      </a>
      <a class="item">
        3
      </a>
    </div>
    <!-- Menu para ambas versiones del lado izquierdo - Dialogos -->
    <div class="ui right sidebar" style="width: 75%;">
      <tickets-new ref="ticketsNew" v-if="dialogViewer === 'tickets-new'" :style="{ height: '100%', maxHeight: '100%', padding: '0px 10px' }"/>
      <tickets-editor ref="ticketsEditor" v-if="dialogViewer === 'tickets-editor'" :style="{ height: '100%', maxHeight: '100%', padding: '0px 10px' }" />
    </div>
    <!-- Renderizacion de elementos parte central -->
    <div class="render-section">
      <router-view :style="{ height: heightRow + 'px' }"/>
    </div>
  </div>
</template>

<script>
import TicketsNew from '@/components/TicketsNew.vue'
import TicketsEditor from '@/components/TicketsEditor.vue'
export default {
  name: 'App',
  components: {
    TicketsNew,
    TicketsEditor
  },
  mounted () {
    // Carga inicial del height actual
    this.refreshHeightGlobal()
    // Carga por cada iteracion de actualizacion del height
    top.onresize = () => {
      this.refreshHeightGlobal()
    }
    // Refrescar el estado de los dialogs en los cambios del sidebar derecho
    $('.ui.sidebar.right').sidebar('setting', 'transition', 'overlay')
    $('.ui.sidebar.right').sidebar('setting', 'onHidden', () => {
      this.$store.dispatch('updateDialogViewer', '')
    })
  },
  computed: {
    heightRow () {
      return this.$store.state.displayHeight
    },
    dialogViewer () {
      return this.$store.state.dialogViewer
    },
    actualPath () {
      return this.$route.path
    },
    filtros () {
      return this.$store.state.filters
    },
    filterCant () {
      return this.$store.state.filterCant
    },
    filterPag () {
      return this.$store.state.filterPag
    }
  },
  data () {
    return {}
  },
  methods: {
    refreshHeightGlobal () {
      this.$store.dispatch('updateDisplayHeight', top.innerHeight)
    },
    applyFilther (type = 'normal', __callback) {
      if ((this.filtros.fechaInicio === null && this.filtros.fechaFinal !== null) || (this.filtros.fechaInicio !== null && this.filtros.fechaFinal === null)) {
        $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: 'No se puede aplicar el filtro ya que el rango de fechas esta definido de forma incompleta' })
      } else {
        // se generan las propiedades para ejecutar el filtro
        let lim = 18 // Limite de datos por pagina
        let offs = this.filterPag * 18 // Items mostrados
        let consecutivo = this.filtros.consecutivo
        let fecha1 = this.filtros.fechaInicio === null ? '1999-01-01' : `${this.filtros.fechaInicio.toISOString().split('T')[0]}`
        let fecha2 = this.filtros.fechaFinal === null ? '1999-01-01' : `${this.filtros.fechaFinal.toISOString().split('T')[0]}`
        let usuarios = JSON.stringify(this.filtros.usuarios).replace('[', '').replace(']', '')
        let entidades = JSON.stringify(this.filtros.entidades).replace('[', '').replace(']', '')
        let proyectos = this.filtros.proyectos === null ? '' : this.filtros.proyectos
        let modulos = JSON.stringify(this.filtros.modulos).replace('[', '').replace(']', '')
        let estados = JSON.stringify(this.filtros.estados).replace('[', '').replace(']', '')
        let origenes = JSON.stringify(this.filtros.origenes).replace('[', '').replace(']', '')
        // Ejecutar la solicitud
        if (type === 'normal') {
          this.$store.dispatch('filterResultUpdate', [])
          this.$store.dispatch('filterStateToggle')
          this.$soportecore.api.source.getTicketsPaginacion(lim, offs, consecutivo, fecha1, fecha2, usuarios, entidades, proyectos, modulos, estados, origenes).then((rta) => {
            this.$store.dispatch('filterResultUpdate', rta.result.contenido)
            this.$store.dispatch('filterCantUpdate', rta.result.cantidad)
            this.$store.dispatch('filterStateToggle')
          }).catch((err) => {
            if (err.code !== 400) $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar obtener los tickets` })
            console.log(err)
            this.$store.dispatch('filterStateToggle')
          })
          // Ejcutar consulta de los indicadores
          this.$soportecore.api.source.getTicketContadores(usuarios).then((rta) => {
            this.$store.dispatch('filterContadorUpdate', rta.result[0])
          }).catch((err) => {
            if (err.code !== 400) $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar obtener los consecutivos` })
            console.log(err)
          })
        } else {
          this.$store.dispatch('filterStateToggle', 'Generando reporte...')
          this.$soportecore.api.source.getReportTicketsGeneral(consecutivo, fecha1, fecha2, usuarios, entidades, proyectos, modulos, estados, origenes).then((rta) => {
            this.$store.dispatch('filterStateToggle')
            __callback(rta)
          }).catch((err) => {
            if (err.code !== 400) $('body').toast({ class: 'error', position: 'bottom left', displayTime: 10000, message: `Ha ocurrido el error ${err.code} - ${err.help} al momento de intentar obtener los tickets` })
            console.log(err)
            this.$store.dispatch('filterStateToggle')
          })
        }
      }
    }
  },
  watch: {
    dialogViewer: function (val) {
      if (val === '') {
        $('.ui.sidebar.right').sidebar('hide')
      } else if (val !== '') {
        $('.ui.sidebar.right').sidebar('show')
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import './assets/soporte-variables.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  color: $--font-primary-color;
  background-color: $--background-primary-color;
  > * {
    transition: all 0.3s;
    font-family: 'Open Sans', sans-serif!important;
  }
}
.login-background {
  // background-image: url('./assets/loginbackground.jpg');
  // background-size: cover;
  background-color: #11232D;
}
.default-background {
  background-color: $--background-primary-color;
}
</style>
