<template>
<!-- TODO: corregir error en el contador de elementos -->
  <div>
    <!-- Profile section -->
    <perfil-card/>
    <!-- Primera Seccion de botones de creacion -->
    <div v-show="details === 'normal'" class="ui vertical small fluid menu">
      <a class="item" @click.stop="openDialog('tickets-new')">
        Nuevo Ticket
        <i class="file alternate outline icon"></i>
      </a>
      <a class="item" @click.stop="openConfiguracion">
        Configuracion
        <i class="cog icon"></i>
      </a>
    </div>
    <!-- Botones de filtro -->
    <div v-show="details === 'normal'" :style="{ height: heightRow + 'px', maxHeight: heightRow + 'px', overflow: 'auto', padding: '0px 5px' }">
      <!-- Primer selector de categoria por estado general -->
      <div class="ui vertical small fluid menu">
        <div class="header item">Estados</div>
        <a :class="`${filters.estados.length === 5 ? 'active teal' : ''} item`" @click.stop="changeFilter('estados', [1, 2, 3, 4, 5])">
          Todos
          <!-- <div :class="`ui ${filters.estados.length === 5 ? 'teal' : ''} label`">{{ contadores.todosE }}</div> -->
        </a>
        <a :class="`${filters.estados.length === 1 && filters.estados[0] === 1 ? 'active teal' : ''} item`" @click.stop="changeFilter('estados', [1])">
          Registrados
          <!-- <div :class="`ui ${filters.estados.length === 1 && filters.estados[0] === 1 ? 'teal' : ''} label`">{{ contadores.registrados }}</div> -->
        </a>
        <a :class="`${filters.estados.length === 2 ? 'active teal' : ''} item`" @click.stop="changeFilter('estados', [2, 3])">
          En proceso
          <!-- <div :class="`ui ${filters.estados.length === 2 ? 'teal' : ''} label`">{{ contadores.enProceso }}</div> -->
        </a>
        <a :class="`${filters.estados.length === 1 && filters.estados[0] === 4 ? 'active teal' : ''} item`" @click.stop="changeFilter('estados', [4])">
          Resueltos
          <!-- <div :class="`ui ${filters.estados.length === 1 && filters.estados[0] === 4 ? 'teal' : ''} label`">{{ contadores.resueltos }}</div> -->
        </a>
        <a :class="`${filters.estados.length === 1 && filters.estados[0] === 5 ? 'active teal' : ''} item`" @click.stop="changeFilter('estados', [5])">
          Cerrados
          <!-- <div :class="`ui ${filters.estados.length === 1 && filters.estados[0] === 5 ? 'teal' : ''} label`">{{ contadores.cerrados }}</div> -->
        </a>
      </div>
      <!-- Segundo selector de categoria por origen del ticket -->
      <div class="ui vertical small fluid menu">
        <div class="header basic item">Origenes</div>
        <a :class="`${filters.origenes.length === 6 ? 'active teal' : ''} item`" @click.stop="changeFilter('origenes', [1, 2, 3, 4, 5, 6])">
          Todos
          <!-- <div :class="`ui ${filters.origenes.length === 6 ? 'teal' : ''} label`">{{ contadores.todosO }}</div> -->
        </a>
        <a :class="`${filters.origenes.length === 1 && filters.origenes[0] === 1 ? 'active teal' : ''} item`" @click.stop="changeFilter('origenes', [1])">
          Email
          <!-- <div :class="`ui ${filters.origenes.length === 1 && filters.origenes[0] === 1 ? 'teal' : ''} label`">{{ contadores.email }}</div> -->
        </a>
        <a :class="`${filters.origenes.length === 1 && filters.origenes[0] === 2 ? 'active teal' : ''} item`" @click.stop="changeFilter('origenes', [2])">
          Fijo
          <!-- <div :class="`ui ${filters.origenes.length === 1 && filters.origenes[0] === 2 ? 'teal' : ''} label`">{{ contadores.fijo }}</div> -->
        </a>
        <a :class="`${filters.origenes.length === 1 && filters.origenes[0] === 3 ? 'active teal' : ''} item`" @click.stop="changeFilter('origenes', [3])">
          Movil
          <!-- <div :class="`ui ${filters.origenes.length === 1 && filters.origenes[0] === 3 ? 'teal' : ''} label`">{{ contadores.movil }}</div> -->
        </a>
        <a :class="`${filters.origenes.length === 1 && filters.origenes[0] === 4 ? 'active teal' : ''} item`" @click.stop="changeFilter('origenes', [4])">
          Whatsapp
          <!-- <div :class="`ui ${filters.origenes.length === 1 && filters.origenes[0] === 4 ? 'teal' : ''} label`">{{ contadores.whatsapp }}</div> -->
        </a>
        <a :class="`${filters.origenes.length === 1 && filters.origenes[0] === 5 ? 'active teal' : ''} item`" @click.stop="changeFilter('origenes', [5])">
          Plataforma
          <!-- <div :class="`ui ${filters.origenes.length === 1 && filters.origenes[0] === 5 ? 'teal' : ''} label`">{{ contadores.plataforma }}</div> -->
        </a>
        <a :class="`${filters.origenes.length === 1 && filters.origenes[0] === 6 ? 'active teal' : ''} item`" @click.stop="changeFilter('origenes', [6])">
          Fisico
          <!-- <div :class="`ui ${filters.origenes.length === 1 && filters.origenes[0] === 6 ? 'teal' : ''} label`">{{ contadores.fisico }}</div> -->
        </a>
      </div>
    </div>
    <!-- Botones de menu de Configuracion -->
    <div v-show="details === 'configuration'" class="ui vertical small fluid pointing menu">
      <a class="item" @click.stop="openBack">
        Regresar
        <i class="arrow left icon"></i>
      </a>
      <a v-if="user.permisoAdministrador === 1" :class="`${viewer === 'grupos' ? 'active' : ''} item`" @click.stop="updateViewer('grupos')">
        Grupos y Permisos
        <i class="users icon"></i>
      </a>
      <a v-if="user.permisoAdministrador === 1" :class="`${viewer === 'usuarios' ? 'active' : ''} item`" @click.stop="updateViewer('usuarios')">
        Usuarios
        <i class="user icon"></i>
      </a>
      <a :class="`${viewer === 'entidades' ? 'active' : ''} item`" @click.stop="updateViewer('entidades')">
        Entidades
        <i class="university icon"></i>
      </a>
      <a :class="`${viewer === 'funcionarios' ? 'active' : ''} item`" @click.stop="updateViewer('funcionarios')">
        Funcionarios
        <i class="user nurse icon"></i>
      </a>
      <a :class="`${viewer === 'proyectos' ? 'active' : ''} item`" @click.stop="updateViewer('proyectos')">
        Proyectos
        <i class="cubes icon"></i>
      </a>
      <a :class="`${viewer === 'modulos' ? 'active' : ''} item`" @click.stop="updateViewer('modulos')">
        Modulos
        <i class="cube icon"></i>
      </a>
    </div>
  </div>
</template>

<script>
import PerfilCard from './PerfilCard'
export default {
  name: 'filter-menu',
  props: {
    details: {
      type: String,
      default: 'normal'
    }
  },
  components: { PerfilCard },
  computed: {
    heightRow () {
      return this.$store.state.displayHeight - 235
    },
    filters () {
      return this.$store.state.filters
    },
    contadores () {
      return this.$store.state.filterContadores
    },
    viewer () {
      return this.$store.state.configViewer
    },
    user () {
      return this.$store.state.user.procesos[0].procedimientos[0]
    }
  },
  data () {
    return {
      cantsEstados: {
        todos: 0,
        registrados: 0,
        enproceso: 0,
        resueltos: 0,
        cerrados: 0
      },
      cantsOrigenes: {
        todos: 0,
        email: 0,
        fijo: 0,
        movil: 0,
        whatsapp: 0,
        plataforma: 0,
        fisico: 0
      }
    }
  },
  methods: {
    openDialog (viewer) {
      this.$store.dispatch('updateDialogViewer', viewer)
    },
    openConfiguracion () {
      this.$router.push({ path: '/config' })
    },
    openBack () {
      this.$router.push({ path: '/' })
    },
    updateViewer (viewer) {
      this.$store.dispatch('configViewerUpdate', viewer)
    },
    changeFilter (name, value) {
      this.$store.dispatch('filtersUpdate', { name: name, value: value })
      this.$store.dispatch('filterPag', 0)
      this.$root.$children[0].applyFilther()
    }
  }
}
</script>
