<template>
  <div class="ui raised segment">
    <form class="ui form">
      <h4 class="ui dividing header">{{ `${type === 'new' ? 'Agregar' : 'Editar '} un Correo de Notificacion` }}</h4>
      <div class="field">
        <label>Nombres y Apellidos</label>
        <input v-model="form.nombre" type="text" name="nombre" placeholder="Nombres y apellidos de a quien se vas a notificar">
      </div>
      <div class="field">
        <label>Correo electronico</label>
        <input v-model="form.email" type="email" name="email" placeholder="Correo electronico de notificacion">
      </div>
      <div class="field">
        <label>Modo de notificacion</label>
        <select class="ui dropdown" v-model="form.modo">
          <option v-for="modo in modosOptions" :key="modo.key" :value="modo.value">
            {{ modo.caption }}
          </option>
        </select>
      </div>
      <div class="ui submit buttons">
        <div class="ui button" @click.stop="handleClose">Cancelar</div>
        <div class="or" data-text="o"></div>
        <div class="ui positive button" @click.stop="handleSave">{{ type === 'new' ? 'Agregar' : 'Modificar' }}</div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'EmailNew',
  props: {
    type: {
      type: String,
      default: 'new'
    },
    closeEvent: {
      type: Function,
      default: null
    },
    saveEvent: {
      type: Function,
      default: null
    },
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      state: 'idle',
      form: {
        auto: 0,
        nombre: null,
        email: null,
        modo: 0
      },
      modosOptions: [
        { key: 0, value: 0, caption: 'Correo Directo' },
        { key: 1, value: 1, caption: 'Correo Copia (CC)' },
        { key: 2, value: 2, caption: 'Correo Copia Oculta (BCC)' }
      ]
    }
  },
  mounted () {
    if (this.value !== null) this.form = this.value
  },
  methods: {
    handleClose () {
      if (this.closeEvent !== null) this.closeEvent()
    },
    handleSave () {
      const errors = 0
      if (this.form.nombre === null) {
        $('body').toast({ class: 'error', position: 'bottom left', message: 'No ha especificado el nombre' })
        errors++
      }
      if (this.form.email === null) {
        $('body').toast({ class: 'error', position: 'bottom left', message: 'No ha especificado el correo electronico' })
        errors++
      }
      if (errors === 0) if (this.saveEvent !== null) this.saveEvent(this.form)
    }
  }
}
</script>