<template>
  <div class="ui raised segment">
    <div class="ui active inverted dimmer" v-if="state === 'load'">
      <div class="ui active slow green double text loader">{{ loadText }}</div>
    </div>
    <div class="ui form">
      <h3 class="ui dividing header">Nuevo Item para el Ticket</h3>
      <div class="field">
        <label>Funcionario que solicita || Tipo de Solicitud</label>
        <div class="fields">
          <div class="twelve wide field">
            <list-selector ref="listSelectorPersonas" element-select="funcionario" :elements="personas" :element-select-event="handleClickSelectorPersonal" element-sub-caption="cargo">
              <template v-slot:editor>
                <personal-new :entidad="entidad" :reload-event="chargePersonal"/>
              </template>
            </list-selector>
          </div>
          <div class="four wide field">
            <select class="ui dropdown" v-model="form.tipo">
              <option v-for="tipo in tipos" :key="tipo.key" :value="tipo.value">
                {{ tipo.caption }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Proyectos || Modulos</label>
        <div class="two fields">
          <div class="field">
            <list-selector ref="listSelectorProyecto" element-select="proyecto" :elements="proyectos" :element-select-event="handleClickSelectorProyecto" element-sub-caption="auto">
              <template v-slot:editor>
                <proyecto-new :reload-event="chargeProyectos"/>
              </template>
            </list-selector>
          </div>
          <div  v-if="form.proyecto !== null" class="field">
            <list-selector ref="listSelectorModulo" element-select="modulo" :elements="modulos" :element-select-event="handleClickSelectorModulo" element-sub-caption="auto">
              <template v-slot:editor>
                <modulo-new :proyecto="form.proyecto" :reload-event="chargeModulos"/>
              </template>
            </list-selector>
          </div>
        </div>
      </div>
      <div v-if="form.proyecto !== null && form.modulo !== null" class="field">
        <label>Descripcion</label>
        <div class="ui left corner labeled input">
          <div class="ui left corner label">
              <i class="info icon"></i>
          </div>
          <textarea v-model="form.descripcion"></textarea>
        </div>
      </div>
      <div v-show="false" class="field">
        <label>Anexos</label>
        <list-anexos ref="listanex"/>
      </div>
      <div class="ui submit buttons">
        <div class="ui button" @click="handleClose">Cancelar</div>
        <div class="or" data-text="o"></div>
        <div class="ui positive button" @click="handleSave">Finalizar</div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from '../libs/helpers.js'
import ListSelector from './ListSelector'
import ListAnexos from './ListAnexos'
import PersonalNew from './PersonalNew'
import ProyectoNew from './ProyectoNew'
import ModuloNew from './ModuloNew'
export default {
  name: 'tickets-items-new',
  props: {
    personas: {
      type: Array,
      default: () => { return [] }
    },
    entidad: {
      type: Object,
      default: null
    },
    cancelEvent: {
      type: Function,
      default: null
    },
    saveEvent: {
      type: Function,
      default: null
    },
    value: {
      type: Object,
      default: null
    }
  },
  computed: {
    formProyecto () {
      return this.form.proyecto
    },
    tipos () {
      return helper.itemTicketTipo
    }
  },
  components: {
    ListSelector,
    ListAnexos,
    PersonalNew,
    ProyectoNew,
    ModuloNew
  },
  data () {
    return {
      state: 'idle',
      loadText: 'Un momento',
      proyectos: [],
      modulos: [],
      form: {
        personal: null,
        proyecto: null,
        modulo: null,
        descripcion: '',
        tipo: 1,
        anexos: []
      }
    }
  },
  mounted () {
    if (this.value !== null) {
      this.setValue()
    } else {
      this.chargeProyectos()
    }
  },
  methods: {
    chargeProyectos () {
      this.state = 'load'
      this.loadText = 'Un momento cargando Proyectos'
      this.$soportecore.api.source.getProyectos().then((rta) => {
        this.proyectos = rta.result
        if (this.form.proyecto !== null) this.$refs['listSelectorProyecto'].setElement(this.form.proyecto)
        this.state = 'idle'
      }).catch((err) => {
        if (err.code === 400) {
          this.personas = []
          this.state = 'idle'
          $('body').toast({ class: 'warning', position: 'bottom left', displaytime: 8000, message: 'No existen proyectos, recuerde crear alguno para poder crear el item satisfactoriamente.' })
        }
      })
    },
    chargeModulos () {
      this.state = 'load'
      this.loadText = 'Un momento cargando Proyectos'
      this.$soportecore.api.source.getModulos(this.form.proyecto.auto).then((rta) => {
        this.modulos = rta.result
        if (this.form.modulo !== null) this.$refs['listSelectorModulo'].setElement(this.form.modulo)
        this.state = 'idle'
      }).catch((err) => {
        if (err.code === 400) {
          this.personas = []
          this.state = 'idle'
          $('body').toast({ class: 'warning', position: 'bottom left', displaytime: 8000, message: 'No existen modulos en este proyecto, recuerde crear alguno para poder crear el item satisfactoriamente.' })
        }
      })
    },
    chargePersonal () {
      this.state = 'load'
      this.loadText = 'Un momento cargando Personal'
      this.$soportecore.api.source.getPersonal(this.entidad.auto).then((rta) => {
        this.personas = rta.result
        this.state = 'idle'
      }).catch((err) => {
        if (err.code === 400) {
          this.personas = []
          this.state = 'idle'
          $('body').toast({ class: 'warning', position: 'bottom left', displaytime: 8000, message: 'No existe personal en esta entidad, recuerde crear alguno para poder crear el item satisfactoriamente.' })
        }
      })
    },
    handleClickSelectorProyecto (val) {
      this.form.proyecto = val
    },
    handleClickSelectorModulo (val) {
      this.form.modulo = val
    },
    handleClickSelectorPersonal (val) {
      this.form.personal = val
    },
    handleClose () {
      if (this.cancelEvent !== null) this.cancelEvent()
    },
    handleSave () {
      // Obtencion de los anexos al item
      this.form.anexos = this.$refs['listanex'].getElements()
      // Verificacion de los datos minimos para guardar un item de un ticket
      let errors = 0
      if (this.form.personal === null) {
        $('body').toast({ class: 'error', position: 'bottom left', message: 'No ha seleccionado un solicitante' })
        errors++
      }
      if (this.form.proyecto === null) {
        $('body').toast({ class: 'error', position: 'bottom left', message: 'No ha seleccionado un proyecto' })
        errors++
      }
      if (this.form.modulo === null) {
        $('body').toast({ class: 'error', position: 'bottom left', message: 'No ha seleccionado un modulo del proyecto' })
        errors++
      }
      if (this.form.descripcion === '') {
        $('body').toast({ class: 'error', position: 'bottom left', message: 'No ha especificado el motivo de este item en la descripcion' })
      }
      if (errors === 0) if (this.saveEvent !== null) this.saveEvent(this.form)
    },
    setValue () {
      this.form = this.value
      this.$refs['listSelectorPersonas'].setElement(this.form.personal)
      this.$refs['listanex'].anexos = this.form.anexos
      this.chargeProyectos()
    }
  },
  watch: {
    formProyecto: function (val) {
      if (val !== null) this.chargeModulos()
    }
  }
}
</script>
